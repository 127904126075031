import { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex, Content, ProgressCircle } from '@adobe/react-spectrum';
import moment from 'moment';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { ListDocumentRequest } from '../../../../services/soap/document_library/requests/ListDocumentRequest';
import { PermanentlyDeleteDocumentRequest } from '../../../../services/soap/document_library/requests/PermanentlyDeleteDocumentRequest';
import DocumentTable from './DocumentTable';
import styles from './DocumentComponent.module.css';
interface DocumentProps {}

function Document(props: DocumentProps) {
  const PAGE_SIZE: number = 100;

  const [reloadComponent] = useComonentReload();
  const { projectService, documentLibraryService, store } = useDependency();

  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [documentsData, setDocumentsData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const dataRef = useRef<any>({});
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const { LIST } = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));
      //console.log('contextRespone', contextRespone);
      let folderID;
      if (selectedItem) {
        const index = selectedItem.type === FPADataTypes.ACTIVITY ? 1 : 0;
        const context = LIST[index]?.CONTEXT;
        folderID = selectedItem.type === FPADataTypes.FOLDER ? context?.id : context?.parentId;
      } else {
        folderID = undefined;
      }
      //console.log('folderID', folderID);
      dataRef.current = { folderID };
      setShowLoader(false);
    })();
  }, [selectedItem]);

  useEffect(() => {
    (async () => {
      await getDocumentsData();
    })();
  }, [reloadComponent, selectedItem]);

  let getDocumentsData = async () => {
    //const { item: documentTypes } = await documentLibraryService.listDocumentType(new ListDocumentTypeRequest(store.Server, store.SessionId));
    //console.log('documentTypes: ', documentTypes);

    let { DOCUMENT: allDocuments } = await documentLibraryService.listDocument(
      new ListDocumentRequest(store.Server, store.SessionId, {
        limit: PAGE_SIZE,
        offset: 0,
        parent: selectedItem?.id,
        orderBy: 'doc_file_name',
        PRIORITIES: ['aa.doc_name'],
      })
    );

    //console.log('allDocuments', allDocuments);

    const groupedDocuments: any = {};

    // Group documents by categoryName
    allDocuments?.forEach((doc: any) => {
      const category = doc.categoryName || 'No Category';
      if (!groupedDocuments[category]) {
        groupedDocuments[category] = [];
      }

      groupedDocuments[category].push({
        id: doc.id,
        name: doc.name,
        validTo: `${moment(doc.validTo).format('DD/MM/YYYY')}_${moment(doc.validTo).format('hh:mm A')}`,
        fileSize: `${formatFileSize(doc.fileSize)}`,
        createdByName: `${doc.createdByName}_${moment(doc.lastChangeDate).format('DD/MM/YYYY | hh:mm A')}`,
        action: doc.id,
        category: doc.category,
      });
    });

    // Convert grouped object to array and sort by count
    const resultArray = Object.keys(groupedDocuments)
      .map(category => ({
        count: groupedDocuments[category].length.toString(),
        DOCUMENT: groupedDocuments[category],
        type: category,
        category: groupedDocuments[category][0].category,
      }))
      .sort((a: any, b: any) => b.count - a.count);

    //console.log(resultArray);

    setDocumentsData(resultArray);
  };

  const formatFileSize = (bytes: any) => {
    if (bytes >= 1073741824) {
      return (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return bytes + ' bytes';
    }
  };

  const deleteRecord = async (recordId: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);

      let result = await documentLibraryService.permanentlyDeleteDocument(new PermanentlyDeleteDocumentRequest(store.Server, store.SessionId, { id: recordId }));

      // console.log('result', result);
      if (result.result === 'OK') {
        await getDocumentsData();
        ToastQueue.positive(t('record_deleted_successfully', { ns: 'layout_components' }), { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('documents', { ns: 'layout_components' })}
          </Content>
        </Flex>
        <DocumentTable documentsData={documentsData} deleteRecord={deleteRecord} getDocumentsData={getDocumentsData} />
      </Flex>
    );
  }
}

export const DocumentComponent = LayoutComponent(Document);
