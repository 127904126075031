import { ProjectItem } from './ProjectItem';

export function generateBillingItem(
  project: string,
  activity: string,
  part_number: string,
  product_name: string,
  quantity: number,
  unit_price: number,
  total_price: number,
  totalAmountWithVat: number,
  currency: string,
  vatValue: string,
  purchasePrice: string,
  u_item: ProjectItem = {}
): ProjectItem {
  let item: ProjectItem = {
    ...u_item,
    project: '' + project,
    activity: '' + activity,
    item: part_number,
    amount: '' + unit_price,
    catalogPrice: '' + unit_price,
    totalQuantity: '' + quantity,
    quantity: '' + quantity,
    currency: currency,
    purchaseCurrency: currency,
    totalAmount: '' + total_price,
    totalAmountWithVat: '' + totalAmountWithVat,
    name: product_name,
    vatValue: '' + vatValue,
    purchasePrice: purchasePrice,
    billingStatus: '1',
  };
  return item;
}
