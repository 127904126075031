import { useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { Flex, ProgressCircle } from '@adobe/react-spectrum';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import FolderComponent from './FolderComponent';
import ProjectComponent from './ProjectComponent';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import ActivityComponent from './ActivityComponent';
import FolderComponentMobile from './FolderComponentMobile';
import ProjectComponentMobile from './ProjectComponentMobile';
import ActivityComponentMobile from './ActivityComponentMobile';
import { MenuItemModel } from '@syncfusion/ej2-react-navigations';

export interface IHeaderToolbarProps {
  readonly onPrintClicked?: () => void;
  readonly onEditClicked?: () => void;
  readonly onDeleteClicked?: () => void;
  readonly onAIClicked?: () => void;
}

function HeaderToolbar({ onPrintClicked, onEditClicked, onDeleteClicked, onAIClicked }: IHeaderToolbarProps) {
  const { store, projectService } = useDependency();
  const { isMobile } = useViewInfo();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [headerData, setHeaderData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { t } = useTranslation();

  let menuItems: MenuItemModel[] = [
    {
      iconCss: 'bi bi-printer-fill',
      text: t('print', { ns: 'layout_components' }),
    },
    {
      iconCss: 'bi bi-pencil-square',
      text: t('edit', { ns: 'layout_components' }),
    },
    {
      iconCss: 'bi bi-trash',
      text: t('delete', { ns: 'layout_components' }),
    },
  ];

  useEffect(() => {
    (async () => {
      //console.log('selectedItem', selectedItem);
      setShowLoader(true);
      if (selectedItem?.type === FPADataTypes.FOLDER) {
        const response = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem?.id || 0));
        setHeaderData(response.FOLDER);
        //console.log('response.FOLDER', response.FOLDER);
        setShowLoader(false);
      } else if (selectedItem?.type === FPADataTypes.PROJECT) {
        const response = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem?.id));
        setHeaderData(response.PROJECT);
        //console.log('response.PROJECT', response.PROJECT);
        setShowLoader(false);
      } else if (selectedItem?.type === FPADataTypes.ACTIVITY) {
        const response = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem?.id));
        setHeaderData(response.ACTIVITY);
        //console.log('response.Activity', response);
        if (response.result === 'FAIL') {
          ToastQueue.negative(response.EXCEPTION.message, { timeout: 1000 });
        }
        setShowLoader(false);
      }
    })();
    //console.log('selectedItem in header', selectedItem);
  }, [selectedItem]);

  function statusColor(color: string) {
    //console.log('customStateID', customStateID);
    if (!color || color === '0') return '';
    try {
      let finalColor = (+color).toString(16).split('').reverse();
      let padding = Array(6 - finalColor?.length).fill('0');
      let finalColorString = '#' + [...finalColor, ...padding].join('');
      //console.log('finalColorString', finalColorString);
      return finalColorString;
    } catch (error) {
      return '';
    }
  }

  const handleMenuItemClick = (args: any) => {
    if (args?.item?.text === 'Print') {
      console.log('Print clicked');
      onPrintClicked && onPrintClicked();
    } else if (args?.item?.text === 'Edit') {
      console.log('Edit clicked');
      onEditClicked && onEditClicked();
    } else if (args?.item?.text === 'Delete') {
      console.log('Delete clicked');
      onDeleteClicked && onDeleteClicked();
    }
  };

  if (isMobile) {
    //console.log('selectedItem?.type', selectedItem?.type);
    if (showLoader) {
      return (
        <Flex width="100%" justifyContent={'center'} marginTop={10}>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      );
    } else if (selectedItem?.type === 'folder') {
      return <FolderComponentMobile headerData={headerData} statusColor={statusColor} menuItems={menuItems} handleMenuItemClick={handleMenuItemClick} onAIClicked={onAIClicked} />;
    } else if (selectedItem?.type === 'project') {
      return <ProjectComponentMobile headerData={headerData} statusColor={statusColor} menuItems={menuItems} handleMenuItemClick={handleMenuItemClick} onAIClicked={onAIClicked} />;
    } else if (selectedItem?.type === 'activity') {
      return <ActivityComponentMobile headerData={headerData} statusColor={statusColor} menuItems={menuItems} handleMenuItemClick={handleMenuItemClick} onAIClicked={onAIClicked} />;
    } else {
      return '';
    }
  } else {
    if (showLoader) {
      return (
        <Flex width="100%" justifyContent={'center'} marginTop={10}>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </Flex>
      );
    } else if (selectedItem?.type === 'folder') {
      return <FolderComponent headerData={headerData} statusColor={statusColor} onPrintClicked={onPrintClicked} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />;
    } else if (selectedItem?.type === 'project') {
      return (
        <ProjectComponent headerData={headerData} statusColor={statusColor} onPrintClicked={onPrintClicked} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onAIClicked={onAIClicked} />
      );
    } else if (selectedItem?.type === 'activity') {
      return <ActivityComponent headerData={headerData} statusColor={statusColor} onPrintClicked={onPrintClicked} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />;
    } else {
      return '';
    }
  }
}

export const HeaderToolbarComponent = LayoutComponent(HeaderToolbar);
