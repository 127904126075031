import { PROJECT } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { IGetActivityRequest } from "./requests/GetActivityRequest";
import { IGetContextPathRequest } from "./requests/GetContextPathRequest";
import { IGetFolderProjectTypeRequest } from "./requests/GetFolderProjectTypeRequest";
import { IGetFolderRequest } from "./requests/GetFolderRequest";
import { GetMarketingChannelsStatsRequest } from "./requests/GetMarketingChannelsStatsRequest";
import { IGetProjectRequest } from "./requests/GetProjectRequest";
import { IListActivityTypeRequest } from "./requests/ListActivityTypeRequest";
import { IListContextRequest } from "./requests/ListContextRequest";
import { IListFPARequest } from "./requests/ListFPARequest";
import { IListFPARequest2 } from "./requests/ListFPARequest2";
import { IListFPATypeCategoryRequest } from "./requests/ListFPATypeCategoryRequest";
import { IListFolderTypeRequest } from "./requests/ListFolderTypeRequest";
import { ListMasterWithSlavesRequest } from "./requests/ListMasterWithSlavesRequest";
import { IListProjectTypeRequest } from "./requests/ListProjectTypeRequest";
import { ListStatusRequest } from "./requests/ListStatusRequest";
import { IUpdateActivityRequest, UpdateActivityRequest } from "./requests/UpdateActivityRequest";
import { UpdateFavouriteContextRequest } from "./requests/UpdateFavouriteContextRequest";
import { IUpdateFolderRequest } from "./requests/UpdateFolderRequest";
import { IUpdateProjectRequest } from "./requests/UpdateProjectRequest";
import { GetActivityResponse } from "./responses/GetActivityResponse";
import { GetContextPathResponse } from "./responses/GetContextPathResponse";
import { GetFolderProjectTypeResponse } from "./responses/GetFolderProjectTypeResponse";
import { GetFolderResponse } from "./responses/GetFolderResponse";
import { GetMarketingChannelsStatsResponse } from "./responses/GetMarketingChannelsStatsResponse";
import { GetProjectResponse } from "./responses/GetProjectResponse";
import { ListActivityTypeResponse } from "./responses/ListActivityTypeResponse";
import { ListContextResponse } from "./responses/ListContextResponse";
import { ListFPAResponse } from "./responses/ListFPAResponse";
import { ListFPATypeCategoryResponse } from "./responses/ListFPATypeCategoryResponse";
import { ListFolderTypeResponse } from "./responses/ListFolderTypeResponse";
import { ListMasterWithSlavesResponse } from "./responses/ListMasterWithSlavesResponse";
import { ListProjectTypeResponse, ListProjectTypeResponse2 } from "./responses/ListProjectTypeResponse";
import { ListActivityStatusResponse, ListActivityStatusResponse2, ListFolderStatusResponse, ListFolderStatusResponse2, ListProjectStatusResponse, ListProjectStatusResponse2 } from "./responses/ListStatusResponse";
import { UpdateActivityResponse } from "./responses/UpdateActivityResponse";
import { UpdateFavouriteContextResponse } from "./responses/UpdateFavouriteContextResponse";
import { UpdateFolderResponse } from "./responses/UpdateFolderResponse";
import { UpdateProjectResponse } from "./responses/UpdateProjectResponse";

export interface IProjectService{
    listFPA(request:IListFPARequest):Promise<ListFPAResponse>;
    listFPA2(request:IListFPARequest2):Promise<ListFPAResponse>;
    listContext(request:IListContextRequest):Promise<ListContextResponse>;
    updateFavouriteContext(request:UpdateFavouriteContextRequest):Promise<UpdateFavouriteContextResponse>;
    listFolderStatus(request:ListStatusRequest):Promise<ListFolderStatusResponse>;
    listFolderStatus2(request:ListStatusRequest):Promise<ListFolderStatusResponse2>;
    listProjectStatus(request:ListStatusRequest):Promise<ListProjectStatusResponse>;
    listProjectStatus2(request:ListStatusRequest):Promise<ListProjectStatusResponse2>;
    listActivityStatus(request:ListStatusRequest):Promise<ListActivityStatusResponse>;
    listActivityStatus2(request:ListStatusRequest):Promise<ListActivityStatusResponse2>;
    listProjectType(request:IListProjectTypeRequest):Promise<ListProjectTypeResponse>;
    listProjectType2(request:IListProjectTypeRequest):Promise<ListProjectTypeResponse2>;
    listMasterWithSlaves(request: ListMasterWithSlavesRequest): Promise<ListMasterWithSlavesResponse>;
    listFolderType(request: IListFolderTypeRequest): Promise<ListFolderTypeResponse>;
    getProject(request: IGetProjectRequest): Promise<GetProjectResponse>;
    getActivity(request: IGetActivityRequest): Promise<GetActivityResponse>;
    getMarketingChannelsStats(request: GetMarketingChannelsStatsRequest): Promise<GetMarketingChannelsStatsResponse>;
    getContextPath(request: IGetContextPathRequest): Promise<GetContextPathResponse>;
    getFolder(request: IGetFolderRequest): Promise<GetFolderResponse>;
    listFPATypeCategory(request: IListFPATypeCategoryRequest): Promise<ListFPATypeCategoryResponse>;
    updateActivity(request: IUpdateActivityRequest): Promise<UpdateActivityResponse>;
    updateFolder(request: IUpdateFolderRequest): Promise<UpdateFolderResponse>;
    updateProject(request: IUpdateProjectRequest): Promise<UpdateProjectResponse>;
    getFolderProjectType(request: IGetFolderProjectTypeRequest): Promise<GetFolderProjectTypeResponse>;
    listActivityType(request: IListActivityTypeRequest): Promise<ListActivityTypeResponse>;
}

export class ProjectService extends ServiceBase implements IProjectService{
    private _addRootElementForContextList(xml:string):string{
        xml = xml.replace('</count><CONTEXT>', '</count><CONTEXTS><CONTEXT>');
        xml = xml.replace('</CONTEXT></ns1:ListContextResponse>', '</CONTEXT></CONTEXTS></ns1:ListContextResponse>');
        return xml;
    }
    private _addRootElementForListStatus(xml:string, tag:string):string{
        xml = xml.replaceAll('</time><STATUS>', '</time><STATUS_LIST><STATUS>');
        xml = xml.replaceAll(`</STATUS></ns1:List${tag}StatusResponse>`, `</STATUS></STATUS_LIST></ns1:List${tag}StatusResponse>`);
        xml = xml.replaceAll('</treeHandle><CATEGORY>', '</treeHandle><CATEGORY_LIST><CATEGORY>');
        xml = xml.replaceAll('</CATEGORY><TYPE>', '</CATEGORY></CATEGORY_LIST><TYPE>');
        xml = xml.replaceAll('</CATEGORY_LIST><TYPE>', '</CATEGORY_LIST><TYPE_LIST><TYPE>');
        xml = xml.replaceAll('</treeHandle><TYPE>', '</treeHandle><TYPE_LIST><TYPE>');
        xml = xml.replaceAll('</TYPE></STATUS>', '</TYPE></TYPE_LIST></STATUS>');
        return xml;
    }
    private _addRootElementForListProjectType(xml:string):string{
        xml = xml.replaceAll('</count><TYPE>', '</count><TYPE_LIST><TYPE>');
        xml = xml.replaceAll('</TYPE></ns1:ListProjectTypeResponse>', '</TYPE></TYPE_LIST></ns1:ListProjectTypeResponse>');
        return xml;
    }
    private _addRootElementForListFolderType(xml:string):string{
        xml = xml.replaceAll('</count><TYPE>', '</count><TYPE_LIST><TYPE>');
        xml = xml.replaceAll('</TYPE></ns1:ListFolderTypeResponse>', '</TYPE></TYPE_LIST></ns1:ListFolderTypeResponse>');
        return xml;
    }
    private _addRootElementForStatList(xml:string):string{
        xml = xml.replaceAll('</count><STAT>', '</count><STAT_LIST><STAT>');
        xml = xml.replaceAll('</STAT></ns1:GetMarketingChannelsStatsResponse>', '</STAT></STAT_LIST></ns1:GetMarketingChannelsStatsResponse>');
        return xml;
    }
    private _addRootElementForExternalContact(xml:string):string{
        xml = xml.replaceAll('</count><EXTERNAL_CONTACT>', '</count><ROWS><EXTERNAL_CONTACT>');
        xml = xml.replaceAll('</EXTERNAL_CONTACT></EXTERNAL_CONTACTS>', '</EXTERNAL_CONTACT></ROWS></EXTERNAL_CONTACTS>');
        return xml;
    }
    private _addRootElementForContextInfoList(xml:string):string{
        xml = xml.replace('</count><CONTEXT>', '</count><LIST><CONTEXT>');
        xml = xml.replace('</CONTEXT></ns1:GetContextPathResponse>', '</CONTEXT></LIST></ns1:GetContextPathResponse>');
        return xml;
    }
    private _addRootElementForCategoryList(xml:string):string{
        xml = xml.replace('</count><CATEGORY>', '</count><CATEGORY_LIST><CATEGORY>');
        xml = xml.replace('</CATEGORY></ns1:ListFPATypeCategoryResponse>', '</CATEGORY></CATEGORY_LIST></ns1:ListFPATypeCategoryResponse>');
        return xml;
    }

    public async listFPA2(request: IListFPARequest2): Promise<ListFPAResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listFPA, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(PROJECT.listFPA, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listFPA, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListFPAResponse as ListFPAResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listFPA(request: IListFPARequest): Promise<ListFPAResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listFPA, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(PROJECT.listFPA, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listFPA, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListFPAResponse as ListFPAResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listContext(request: IListContextRequest): Promise<ListContextResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listContext, request.cancelSource));
            response = this._addRootElementForContextList(tmp_response.data);
            await this.updateCache(PROJECT.listContext, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listContext, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListContextResponse as ListContextResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async updateFavouriteContext(request: UpdateFavouriteContextRequest): Promise<UpdateFavouriteContextResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.updateFavouriteContext, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.updateFavouriteContext, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.updateFavouriteContext, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateFavouriteContextResponse as UpdateFavouriteContextResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listFolderStatus(request: ListStatusRequest): Promise<ListFolderStatusResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listFolderStatus, request.cancelSource));
            response = this._addRootElementForListStatus(tmp_response.data, 'Folder');
            await this.updateCache(PROJECT.listFolderStatus, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listFolderStatus, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListFolderStatusResponse as ListFolderStatusResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listFolderStatus2(request: ListStatusRequest): Promise<ListFolderStatusResponse2> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listFolderStatus, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.listFolderStatus, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listFolderStatus, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['STATUS', 'CATEGORY', 'TYPE']).ListFolderStatusResponse as ListFolderStatusResponse2;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listProjectStatus(request: ListStatusRequest): Promise<ListProjectStatusResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listProjectStatus, request.cancelSource));
            response = this._addRootElementForListStatus(tmp_response.data, 'Project');
            await this.updateCache(PROJECT.listProjectStatus, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listProjectStatus, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListProjectStatusResponse as ListProjectStatusResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listProjectStatus2(request: ListStatusRequest): Promise<ListProjectStatusResponse2> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listProjectStatus, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.listProjectStatus, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listProjectStatus, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['STATUS', 'CATEGORY', 'TYPE']).ListProjectStatusResponse as ListProjectStatusResponse2;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listActivityStatus(request: ListStatusRequest): Promise<ListActivityStatusResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listActivityStatus, request.cancelSource));
            response = this._addRootElementForListStatus(tmp_response.data, 'Activity');
            await this.updateCache(PROJECT.listActivityStatus, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listActivityStatus, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListActivityStatusResponse as ListActivityStatusResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
    
    public async listActivityStatus2(request: ListStatusRequest): Promise<ListActivityStatusResponse2> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listActivityStatus, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.listActivityStatus, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listActivityStatus, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['STATUS', 'CATEGORY', 'TYPE']).ListActivityStatusResponse as ListActivityStatusResponse2;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
    
    public async listMasterWithSlaves(request: ListMasterWithSlavesRequest): Promise<ListMasterWithSlavesResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listMasterWithSlaves, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.listMasterWithSlaves, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listMasterWithSlaves, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListMasterWithSlavesResponse as ListMasterWithSlavesResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listProjectType(request: IListProjectTypeRequest): Promise<ListProjectTypeResponse> {
        let response: any = null;
        let action = PROJECT.listProjectType;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), action, request.cancelSource));
            response = this._addRootElementForListProjectType(tmp_response.data);
            await this.updateCache(action, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(action, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListProjectTypeResponse as ListProjectTypeResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listProjectType2(request: IListProjectTypeRequest): Promise<ListProjectTypeResponse2> {
        let response: any = null;
        let action = PROJECT.listProjectType;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), action, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(action, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(action, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['TYPE', 'CATEGORY']).ListProjectTypeResponse as ListProjectTypeResponse2;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listFolderType(request: IListFolderTypeRequest): Promise<ListFolderTypeResponse> {
        let response: any = null;
        let action = PROJECT.listFolderType;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), action, request.cancelSource));
            response = this._addRootElementForListFolderType(tmp_response.data);
            await this.updateCache(action, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(action, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListFolderTypeResponse as ListFolderTypeResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getProject(request: IGetProjectRequest): Promise<GetProjectResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.getProject, request.cancelSource));
            response = this._addRootElementForExternalContact(tmp_response.data);
            await this.updateCache(PROJECT.getProject, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.getProject, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetProjectResponse as GetProjectResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        if(parsedResponse.PROJECT.EXTERNAL_CONTACTS.count === "0"){
            parsedResponse.PROJECT.EXTERNAL_CONTACTS.ROWS = [] as any;
        }
        return parsedResponse;        
    }

    public async getActivity(request: IGetActivityRequest): Promise<GetActivityResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.getActivity, request.cancelSource));
            response = this._addRootElementForExternalContact(tmp_response.data);
            await this.updateCache(PROJECT.getActivity, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.getActivity, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetActivityResponse as GetActivityResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
    public async getMarketingChannelsStats(request: GetMarketingChannelsStatsRequest): Promise<GetMarketingChannelsStatsResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.getMarketingChannelsStats, request.cancelSource));
            response = this._addRootElementForStatList(tmp_response.data);
            await this.updateCache(PROJECT.getMarketingChannelsStats, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.getMarketingChannelsStats, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetMarketingChannelsStatsResponse as GetMarketingChannelsStatsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async getContextPath(request: IGetContextPathRequest): Promise<GetContextPathResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.getContextPath, request.cancelSource));
            response = this._addRootElementForContextInfoList(tmp_response.data);
            await this.updateCache(PROJECT.getContextPath, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.getContextPath, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetContextPathResponse as GetContextPathResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async getFolder(request: IGetFolderRequest): Promise<GetFolderResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.getFolder, request.cancelSource));
            response = this._addRootElementForExternalContact(tmp_response.data);
            await this.updateCache(PROJECT.getFolder, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.getFolder, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetFolderResponse as GetFolderResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listFPATypeCategory(request: IListFPATypeCategoryRequest): Promise<ListFPATypeCategoryResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listFPATypeCategory, request.cancelSource));
            response = this._addRootElementForCategoryList(tmp_response.data);
            await this.updateCache(PROJECT.listFPATypeCategory, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listFPATypeCategory, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListFPATypeCategoryResponse as ListFPATypeCategoryResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async updateActivity(request: IUpdateActivityRequest): Promise<UpdateActivityResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.updateActivity, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.updateActivity, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.updateActivity, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateActivityResponse as UpdateActivityResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse
    }

    public async updateFolder(request: IUpdateFolderRequest): Promise<UpdateFolderResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.updateFolder, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.updateFolder, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.updateFolder, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateFolderResponse as UpdateFolderResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async updateProject(request: IUpdateProjectRequest): Promise<UpdateProjectResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.updateProject, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.updateProject, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.updateProject, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateProjectResponse as UpdateProjectResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async getFolderProjectType(request: IGetFolderProjectTypeRequest): Promise<GetFolderProjectTypeResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.getFolderProjectType, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.getFolderProjectType, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.getFolderProjectType, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response).GetFolderProjectTypeResponse as GetFolderProjectTypeResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listActivityType(request: IListActivityTypeRequest): Promise<ListActivityTypeResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), PROJECT.listActivityType, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(PROJECT.listActivityType, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(PROJECT.listActivityType, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['TYPE', 'CATEGORY']).ListActivityTypeResponse as ListActivityTypeResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}