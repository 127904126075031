import React, { useEffect, useState } from 'react';
import { ActionButton, ComboBox, Content, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, Section, TextField, View, useAsyncList } from '@adobe/react-spectrum';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListCurrencyRequest } from '../../../../services/soap/global/requests/ListCurrencyRequest';
import { useTranslation } from 'react-i18next';
import { ListItemRequest } from '../../../../services/soap/item/requests/ListItemRequest';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useBeforeRender } from '../../../../hooks/useBeforeRender';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { GetFinanceSettingsRequest } from '../../../../services/soap/finance/requests/GetFinanceSettingsRequest';
import { RowData } from './BillingLineComponent';

interface BillingLineAddEditProps {
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  closeDialog: (isOpen: boolean) => void;
  isUnitPriceColumnVisible?: boolean;
  isTotalPriceColumnVisible?: boolean;
  styles: any;
}

interface Character {
  name: string;
}

const BillingLineAddEdit: React.FC<BillingLineAddEditProps> = ({
  id,
  defaultRowData,
  selectedRowData,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  closeDialog,
  isUnitPriceColumnVisible,
  isTotalPriceColumnVisible,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const { itemService, globalService, financeService, store } = useDependency();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);
  const [currencyList, setCurrencyList] = useState<any>([]);
  const [currencySetting, setCurrencySetting] = useState<string>('');
  const [partNumberData, setPartNumberData] = useState<any>([]);
  const [validationMessages, setValidationMessages] = useState<any>({ partNumber: '', quantity: '', unitPrice: '', currency: '' });
  const { t } = useTranslation();

  /** Code to hide Observer Error */
  useBeforeRender(() => {
    window.addEventListener('error', e => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  useEffect(() => {
    //console.log('selectedRowData', selectedRowData);
    //console.log('id', id);
    setValidationMessages({ partNumber: selectedRowData.id === '0' ? 'required' : '', quantity: '', unitPrice: '', currency: '' });
    setSelectedRow(selectedRowData || defaultRowData);
    list.setFilterText(selectedRowData?.partNumber || '');
    getCurrencyList();
  }, [id, selectedRowData]);

  const getCurrencyList = async () => {
    let currencyList = await globalService.listCurrency(new ListCurrencyRequest(store.Server, store.SessionId, 50, 0));
    if (currencyList?.result === 'OK') {
      //console.log('currencyList', currencyList);
      const formattedArray = currencyList.ROWS.map(({ ITEM }: { ITEM: { id: string; code: string } }) => ({
        id: ITEM.id,
        code: ITEM.code,
      }));
      setCurrencyList(formattedArray);
      //console.log('selectedRow.id', selectedRowData.id);
      selectedRowData.id === '0' && (await getCurrencyDetails(formattedArray));
    }
  };

  const getCurrencyDetails = async (arrCurrencyList: any) => {
    let currencySetting = await financeService.getFinanceSettings(new GetFinanceSettingsRequest(store.Server, store.SessionId));
    if (currencySetting.result === 'OK' && currencySetting.count > 0) {
      //console.log('currencySetting', currencySetting.SETTINGS.currency);
      setCurrencySetting(currencySetting.SETTINGS.currency);
      //console.log('arrCurrencyList', arrCurrencyList);
      setSelectedRow(prev => ({ ...prev, currency: arrCurrencyList.find((item: any) => item.id === currencySetting.SETTINGS.currency)?.code }));
    }
  };

  const calculateTotalPrice = () => {
    setSelectedRow(prev => ({ ...prev, totalPrice: (prev.quantity * prev.unitPrice).toFixed(2), totalPriceWithVat: (prev.quantity * prev.amountWithVat).toFixed(2) }));
  };

  const validateFields = () => {
    const fields = [
      { key: 'partNumber', condition: selectedRow.partNumber === '' || selectedRow.partNumber === ' ' },
      { key: 'quantity', condition: false },
      { key: 'unitPrice', condition: false },
      { key: 'currency', condition: selectedRow.currency === '' },
    ];

    const isValid = fields.every(({ key, condition }) => {
      const isFieldValid = validationMessages[key] === '' && !condition;
      if (!isFieldValid) {
        validationMessages[key] = 'required';
        setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
      }
      return isFieldValid;
    });

    return isValid;
  };

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.id) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow);
    action(selectedRow);
    closeDialog(false);
  };

  const findObjectById = (id: string) => {
    let newId = id.endsWith('_ref') ? id.replace('_ref', '_name') : id.replace('_name', '_ref');
    //console.log('id', id);

    for (const group of partNumberData) {
      const found = group.children.find((child: any) => child._refId === id || child._refId === newId);
      if (found) return found;
    }
    return null;
  };

  // Define a custom hook called "list" using the useAsyncList hook
  const list = useAsyncList<Character>({
    async load({ filterText }) {
      // Set filterText to selectedRow's partNumber if filterText is empty
      filterText = filterText || (selectedRow.partNumber ? selectedRow.partNumber : ' ');
      // Update the selectedRow state with the new filterText
      setSelectedRow(prev => ({ ...prev, partNumber: filterText }));
      // Concurrently fetch item data by searchName and refId using filterText
      const [itemResp, itemRespByRefID]: any = await Promise.all([
        itemService.listItem(new ListItemRequest(store.Server, store.SessionId, 10, 0, { searchName: filterText.trim() })),
        itemService.listItem(new ListItemRequest(store.Server, store.SessionId, 10, 0, { refId: filterText.trim() })),
      ]);

      const formatItemRefID = ({ ITEM }: { ITEM: any }) => ({
        id: ITEM.id,
        refId: ITEM.refId,
        _refId: `${ITEM.refId}_${ITEM.name}_ref`, // Custom reference ID combining refId and name
        refIdName: `${ITEM.refId}(${ITEM.name})`, // Display name combining refId and name
        productName: ITEM.name,
        unitPrice: ITEM.amount,
        amountWithVat: ITEM.amountWithVat, // Amount with VAT
        vatValue: ITEM.vatValue, // VAT value
        purchasePrice: ITEM.purchasePrice,
        totalAmountWithVat: ITEM.totalAmountWithVat,
      });

      const formatItemName = ({ ITEM }: { ITEM: any }) => ({
        id: ITEM.id,
        refId: ITEM.refId,
        _refId: ITEM.refId + '_' + ITEM.name + '_name', // Custom reference ID combining refId and name
        refIdName: `${ITEM.name}${ITEM.refId ? `(${ITEM.refId})` : ''}`, // Display name combining refId and name
        productName: ITEM.name,
        unitPrice: ITEM.amount,
        amountWithVat: ITEM.amountWithVat, // Amount with VAT
        vatValue: ITEM.vatValue, // VAT value
        purchasePrice: ITEM.purchasePrice,
        totalAmountWithVat: ITEM.totalAmountWithVat,
      });

      const options = [
        ...(itemRespByRefID.ROWS?.length ? [{ name: `${itemRespByRefID.ROWS.length} ref id match`, children: itemRespByRefID.ROWS.map(formatItemRefID) }] : []),
        ...(itemResp.ROWS?.length ? [{ name: `${itemResp.ROWS.length} name match`, children: itemResp.ROWS.map(formatItemName) }] : []),
      ];
      //console.log('options', options);
      setPartNumberData(options);
      return { items: options, cursor: undefined };
    },
  });

  let onInputChange = (value: any) => {
    list.setSelectedKeys(value === '' ? new Set<any>() : list.selectedKeys);
    validationMessages.partNumber = value === '' ? 'Part number is required' : '';
    setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
    list.setFilterText(value === '' ? ' ' : value);
  };

  const selectedIndexChanged = (value: any) => {
    //console.log('selectedIndexChanged', value);
    //if (!value) return;
    //console.log('partNumberData', partNumberData);'
    let selectedItem: any = findObjectById(value);
    //console.log('selectedItem', selectedItem);
    if (selectedItem) {
      setValidationMessages((prev: any) => ({ ...prev, partNumber: '' }));
      list.setFilterText(selectedItem.refId);
      list.setSelectedKeys(new Set([selectedItem._refId]));
      setSelectedRow(prev => ({
        ...prev,
        _refId: selectedItem._refId,
        partNumber: selectedItem.refId,
        productName: selectedItem.productName,
        partID: selectedItem.id,
        unitPrice: selectedItem.unitPrice,
        amountWithVat: selectedItem.amountWithVat,
        vatValue: selectedItem.vatValue,
        purchasePrice: selectedItem.purchasePrice,
        totalAmountWithVat: selectedItem.totalAmountWithVat,
      }));
      calculateTotalPrice();
    } else {
      list.setFilterText('');
      list.setSelectedKeys(new Set());
      setSelectedRow(prev => ({ ...prev, _refId: '', partNumber: '' }));
      setValidationMessages((prev: any) => ({ ...prev, partNumber: 'required' }));
    }
  };

  const BillingDialog = ({ close }: { close: () => void }) => (
    <Dialog size="S">
      <Flex UNSAFE_className={styles.delete_pop_up_parent}>
        <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_material', { ns: 'layout_components' })}</Content>
        <View UNSAFE_className={styles.delete_pop_up_line}></View>
        <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('remove_material_message', { ns: 'layout_components' })}</View>
        <View UNSAFE_className={styles.buttons_parent}>
          <View UNSAFE_className={styles.button_cancel}>
            <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
              {t('no_keep_it', { ns: 'layout_components' })}
            </Link>
          </View>
          <View UNSAFE_className={styles.button_confirm}>
            <div className={styles.trash_icon}>
              <TrashIcon size={32} />
            </div>
            <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
              {t('yes_remove', { ns: 'layout_components' })}
            </Link>
          </View>
        </View>
        <View UNSAFE_className={styles.ellipse_div}>
          <View UNSAFE_className={styles.delete_alert_icon}>
            <AlertIconFill size={72} />
          </View>
        </View>
      </Flex>
    </Dialog>
  );

  const RemoveBillingButton = () =>
    Number(selectedRowData.id) !== 0 && (
      <Flex marginTop={isMobile ? '10px' : '0'}>
        <DialogTrigger isDismissable type="modal">
          <ActionButton UNSAFE_className={styles.btn_delete}> {t('remove_material', { ns: 'layout_components' })}</ActionButton>
          {close => <BillingDialog close={close} />}
        </DialogTrigger>
      </Flex>
    );

  const BillingActions = () => (
    <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
      <Flex>
        <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex>
      <Flex>
        <Link width={'180px'} UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
          {selectedRowData.id === '0' ? t('add', { ns: 'layout_components' }) : t('edit', { ns: 'layout_components' })}
        </Link>
      </Flex>
    </Flex>
  );

  const BillingListButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'} marginTop={isMobile ? '0' : 'size-100'}>
      <Flex>
        <RemoveBillingButton />
      </Flex>
      <BillingActions />
    </Flex>
  );

  return (
    <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'} UNSAFE_style={{ overflowY: 'auto' }}>
      {isMobile ? (
        <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
          <View>
            <NumberField
              marginTop={'20px'}
              width={'100%'}
              isRequired
              value={selectedRow.quantity}
              label="Quantity"
              onChange={value => {
                validationMessages.quantity = isNaN(value) || value <= 0 ? 'Quantity must be greater than 0' : '';
                setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                setSelectedRow(prev => ({ ...prev, quantity: value }));
                calculateTotalPrice();
              }}
              validationState={validationMessages.quantity !== '' ? 'invalid' : 'valid'}
            />
          </View>
          <View>
            <ComboBox
              width={'100%'}
              label="Part number"
              isRequired
              items={list.items}
              selectedKey={selectedRow._refId && selectedRow._refId}
              defaultInputValue={list.filterText || selectedRow.partNumber}
              inputValue={list.filterText || selectedRow.partNumber}
              onInputChange={onInputChange}
              onSelectionChange={selectedIndexChanged}
              loadingState={list.loadingState}
              onLoadMore={list.loadMore}
              validationState={validationMessages.partNumber !== '' || validationMessages.partNumber === ' ' ? 'invalid' : 'valid'}
            >
              {(item: any) => (
                <Section key={item.name} items={item.children} title={item.name}>
                  {(item: any) => <Item key={item._refId}>{item.refIdName}</Item>}
                </Section>
              )}
            </ComboBox>
          </View>
          <View>
            <TextField width={'100%'} value={selectedRow.productName} label="Product name" onChange={value => setSelectedRow(prev => ({ ...prev, productName: value }))} />
          </View>
          {isTotalPriceColumnVisible && (
            <View>
              <NumberField
                width={'100%'}
                isRequired
                minValue={0}
                value={selectedRow.unitPrice}
                label="Unir price"
                onChange={value => {
                  validationMessages.unitPrice = isNaN(value) || value <= 0 ? 'Unit price must be zero or greater than zero' : '';
                  setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                  setSelectedRow(prev => ({ ...prev, unitPrice: value }));
                  calculateTotalPrice();
                }}
                validationState={validationMessages.unitPrice !== '' ? 'invalid' : 'valid'}
              />
              <Picker
                width={'100%'}
                label="Currency"
                isRequired
                items={currencyList}
                onSelectionChange={value => {
                  setValidationMessages((prev: any) => ({ ...prev, currency: '' }));
                  const currencyCode = currencyList.find((item: any) => item.id === value)?.code;
                  setSelectedRow(prev => ({ ...prev, currency: currencyCode }));
                }}
                selectedKey={currencyList.find((item: any) => item.code === selectedRow.currency)?.id}
                validationState={validationMessages.currency !== '' ? 'invalid' : 'valid'}
              >
                {(item: any) => <Item key={item.id}>{item.code}</Item>}
              </Picker>
            </View>
          )}
          {isTotalPriceColumnVisible && (
            <View>
              <TextField
                width={'100%'}
                isDisabled
                value={`${selectedRow.totalPrice} ${selectedRow.currency}`}
                label="Total Price"
                onChange={value => setSelectedRow(prev => ({ ...prev, totalPrice: value }))}
              />
            </View>
          )}
        </Flex>
      ) : (
        <table className="spectrum-Table" style={{ marginTop: '20px' }} cellSpacing={0} cellPadding={0}>
          <thead className="spectrum-Table-head">
            <tr>
              <th style={{ width: '9%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <NumberField
                    width={'98%'}
                    isRequired
                    hideStepper
                    value={selectedRow.quantity}
                    label=""
                    onChange={value => {
                      validationMessages.quantity = isNaN(value) || value <= 0 ? 'Quantity must be greater than 0' : '';
                      setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                      setSelectedRow(prev => ({ ...prev, quantity: value }));
                      calculateTotalPrice();
                    }}
                    validationState={validationMessages.quantity !== '' ? 'invalid' : 'valid'}
                  />
                </span>
              </th>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <ComboBox
                    isRequired
                    width={'98%'}
                    items={list.items}
                    selectedKey={selectedRow._refId && selectedRow._refId}
                    defaultInputValue={selectedRow.partNumber}
                    inputValue={list.filterText || selectedRow.partNumber}
                    onInputChange={onInputChange}
                    onSelectionChange={selectedIndexChanged}
                    loadingState={list.loadingState}
                    onLoadMore={list.loadMore}
                    validationState={validationMessages.partNumber !== '' || validationMessages.partNumber === ' ' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => (
                      <Section key={item.name} items={item.children} title={item.name}>
                        {(item: any) => <Item key={item._refId}>{item.refIdName}</Item>}
                      </Section>
                    )}
                  </ComboBox>
                </span>
              </th>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <TextField width={'98%'} value={selectedRow.productName} label="" onChange={value => setSelectedRow(prev => ({ ...prev, productName: value }))} />
                </span>
              </th>
              {isUnitPriceColumnVisible && (
                <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <table width={'100%'} style={{ marginTop: '-2px' }}>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width={'55%'}>
                            <NumberField
                              width={'100%'}
                              hideStepper
                              isRequired
                              minValue={0}
                              value={selectedRow.unitPrice}
                              label=""
                              onChange={value => {
                                validationMessages.unitPrice = isNaN(value) || value <= 0 ? 'Unit price must be zero or greater than zero' : '';
                                setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                                setSelectedRow(prev => ({ ...prev, unitPrice: value }));
                                calculateTotalPrice();
                              }}
                              validationState={validationMessages.unitPrice !== '' ? 'invalid' : 'valid'}
                            />
                          </td>
                          <td>
                            <Picker
                              label=""
                              width={'100%'}
                              items={currencyList}
                              onSelectionChange={value => {
                                setValidationMessages((prev: any) => ({ ...prev, currency: '' }));
                                const currencyCode = currencyList.find((item: any) => item.id === value)?.code;
                                setSelectedRow(prev => ({ ...prev, currency: currencyCode }));
                              }}
                              selectedKey={currencyList.find((item: any) => item.code === selectedRow.currency)?.id || currencySetting}
                              validationState={validationMessages.currency !== '' ? 'invalid' : 'valid'}
                            >
                              {(item: any) => <Item key={item.id}>{item.code}</Item>}
                            </Picker>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                </th>
              )}

              {isTotalPriceColumnVisible && (
                <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <TextField
                      isDisabled
                      width={'98%'}
                      value={`${selectedRow.totalPrice} ${selectedRow.currency}`}
                      label=""
                      onChange={value => setSelectedRow(prev => ({ ...prev, totalPrice: value }))}
                    />
                  </span>
                </th>
              )}
            </tr>
          </thead>
        </table>
      )}
      <BillingListButtons />
    </Flex>
  );
};

export default BillingLineAddEdit;
