import * as React from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex } from '@adobe/react-spectrum';
import { LayoutDefinition } from '../../../../infra/protected/Layout/LayoutDefinitions';
import { ComponentFactory } from '../ComponentFactory';

export interface IVerticalLayoutProps {
    children?: LayoutDefinition[];
}

function VerticalLayout ({ children }: IVerticalLayoutProps) {
    if(!children) return (<>No children</>);
    return (
        <Flex direction="column" gap="size-100">
            {children.map((child, index) => (<ComponentFactory key={index} definition={child} />))}
        </Flex>
    );
}

export const VerticalLayoutComponent = LayoutComponent(VerticalLayout);
