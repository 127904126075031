import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';
import './global.css';
import jsonPackage from '../package.json';
import './i18n/config';
import Loader from './components/ui/Loader';
import { Provider } from 'react-redux';
import { store } from './state/store';

// replace console.* for disable log on production
// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {};
// }

/** SYNCFUSION LICENSE REGISTRATION */
registerLicense(jsonPackage.license);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter basename="/">
    <React.Suspense fallback={<Loader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Suspense>
  </BrowserRouter>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
