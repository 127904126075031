import * as React from "react";
import { LayoutComponent } from "../LayoutComponent";
import { Item, TabList, TabPanels, Tabs, View } from "@adobe/react-spectrum";
import { LayoutDefinition } from "../../../../infra/protected/Layout/LayoutDefinitions";
import { ComponentFactory } from "../ComponentFactory";

export interface ITabsLayoutProps {
    children?: LayoutDefinition[];
}

function TabsLayout({ children }: ITabsLayoutProps) {
    if(!children) return (<>No children</>);
    return (
        <View>
            <Tabs aria-label="Tabs Layout" isEmphasized>
                <TabList>
                    {children.map((child, index) => (<Item key={`item_${index}`}>{child.name}</Item>))}
                </TabList>
                <TabPanels>
                    {children.map((child, index) => (<Item key={`item_${index}`}>
                        <ComponentFactory definition={child} />
                    </Item>))}
                </TabPanels>
            </Tabs>
        </View>
    );
}

export const TabsLayoutComponent = LayoutComponent<ITabsLayoutProps>(TabsLayout);
