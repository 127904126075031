import { Filter } from "./ListProjectItemRequest";

export class ListProjectItemRequestOptions_BillingItem {
  public orderBy: string = "orderindex";
  public activity: string;
  public FILTER: Filter;
  constructor(activity: string) {
    this.activity = activity;
    this.FILTER = {
      COL: [{ name: "type", type: "in", value: "1" }],
    };
  }
}
