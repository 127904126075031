import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListRegisterRequest extends IBaseRequest {
    moduleId:string;
    key:string;
    currentUser?:number;
    level?:number;
}

export class ListRegisterRequest extends BaseRequest implements IListRegisterRequest {
    public moduleId:string;
    public key:string;
    public currentUser?:number;
    public level?:number;
    constructor(server: string, session: string, moduleId:string, key:string, currentUser?:number, level?:number) {
        super(server, session);
        this.moduleId = moduleId;
        this.key = key;
        this.currentUser = currentUser;
        this.level = level;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += '<moduleId>' + this.moduleId + '</moduleId>';
        parameters += '<key>' + this.key + '</key>';
        if (this.currentUser) {
            parameters += '<currentUser>' + this.currentUser + '</currentUser>';
        }
        if (this.level) {
            parameters += '<level>' + this.level + '</level>';
        }
        return super.wrapperXml('ListRegister', parameters);
    }
}
