import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IContact {
  content?: string;
  description?: string;
  isMain?: boolean;
  isPrimary?: boolean;
  type?: string;
  typeName?: string;
  typeType?: string;
  category?: string;
}

export interface IRow {
  id?: string;
}

export interface ICol {
  name?: string;
  desc?: string;
}

export interface IFolderType {
  value?: string;
}

export interface IGetContactListRequest extends IBaseRequest {
  timestamp?: string;
  offset?: number;
  limit?: number;
  treeHandle?: string;
  person?: string;
  company?: string;
  industry?: string;
  department?: string;
  position?: string;
  type?: string;
  onlyIds?: boolean;
  organizationId?: string;
  hasFolder?: boolean;
  showOnlyUc?: boolean;
  name1?: string;
  name2?: string;
  name3?: string;
  RegNumber?: string;
  VATNumber?: string;
  keyId?: string;
  contains?: string;
  hideRelations?: boolean;
  hideFolders?: boolean;
  distributionGroup?: string;
  project?: string;
  showRelated?: boolean;
  contactContact?: string;
  onlyUser?: boolean;
  accessFilter?: string;
  onlyGarant?: boolean;
  onlyAvailable?: boolean;
  availableFrom?: string;
  availableTo?: string;
  taskType?: string;
  notSimilarName?: string;
  viewId?: string;
  workContract?: string;
  region?: string;
  city?: string;
  zip?: string;
  agemin?: number;
  agemax?: number;
  gender?: string;
  countOnly?: boolean;
  folderStatus?: string;
  conjuctionDistrGroups?: string;
  showPersonInfo?: boolean;
  consentStatus?: string;
  consentNote?: string;
  anonymized?: string;
  validFromMin?: string;
  validFromMax?: string;
  validToMin?: string;
  validToMax?: string;
  fpaTypeCategory?: string;
  category?: string;
  externalSystem?: string;
  externalId?: string;
  ORDER?: {
    COL?: ICol[];
  };
  FOLDERTYPE?: IFolderType[];
  CONTACTS?: {
    CONTACT?: IContact[];
  };
  DISTRGROUPS?: {
    ROW?: IRow[];
  };
  USERS?: {
    ID?: string[];
  };
}

export class GetContactListRequest extends BaseRequest implements IGetContactListRequest {
  timestamp?: string;
  offset?: number;
  limit?: number;
  treeHandle?: string;
  person?: string;
  company?: string;
  industry?: string;
  department?: string;
  position?: string;
  type?: string;
  onlyIds?: boolean;
  organizationId?: string;
  hasFolder?: boolean;
  showOnlyUc?: boolean;
  name1?: string;
  name2?: string;
  name3?: string;
  RegNumber?: string;
  VATNumber?: string;
  keyId?: string;
  contains?: string;
  hideRelations?: boolean;
  hideFolders?: boolean;
  distributionGroup?: string;
  project?: string;
  showRelated?: boolean;
  contactContact?: string;
  onlyUser?: boolean;
  accessFilter?: string;
  onlyGarant?: boolean;
  onlyAvailable?: boolean;
  availableFrom?: string;
  availableTo?: string;
  taskType?: string;
  notSimilarName?: string;
    viewId?: string;
  workContract?: string;
  region?: string;
  city?: string;
  zip?: string;
  agemin?: number;
  agemax?: number;
  gender?: string;
  countOnly?: boolean;
  folderStatus?: string;
  conjuctionDistrGroups?: string;
  showPersonInfo?: boolean;
  consentStatus?: string;
  consentNote?: string;
  anonymized?: string;
  validFromMin?: string;
  validFromMax?: string;
  validToMin?: string;
  validToMax?: string;
  fpaTypeCategory?: string;
  category?: string;
  externalSystem?: string;
  externalId?: string;
  ORDER?: {
    COL?: ICol[];
  };
  FOLDERTYPE?: IFolderType[];
  CONTACTS?: {
    CONTACT?: IContact[];
  };
  DISTRGROUPS?: {
    ROW?: IRow[];
  };
  USERS?: {
    ID?: string[];
  };

  constructor(_server: string, _session: string, _timestampe?: string, _offset? :number, 
    _limit?: number, _treeHandle?: string, _person?: string, _company?: string, _industry?: string, _department?: string,
    _position?: string, _type?: string, _onlyIds?: boolean, _organizationId?: string, _hasFolder?: boolean, _showOnlyUc?: boolean,
    _name1?: string, _name2?: string, _name3?: string, _RegNumber?: string, _VATNumber?: string, _keyId?: string, _contains?: string,
    _hideRelations?: boolean, _hideFolders?: boolean, _distributionGroup?: string, _project?: string, _showRelated?: boolean,
    _contactContact?: string, _onlyUser?: boolean, _accessFilter?: string, _onlyGarant?: boolean, _onlyAvailable?: boolean,
    _availableFrom?: string, _availableTo?: string, _taskType?: string, _notSimilarName?: string, _viewId?: string,
    _workContract?: string, _region?: string, _city?: string, _zip? :string, _agemin? :number, _agemax?: number,
    _countOnly?: boolean, _folderStatus?: string, _conjuctionDistrGroups?: string, _showPersonInfo?: boolean, _consentStatus?: string,
    _consentNote?: string, _anonymized?: string, _validFromMin?: string, _validFromMax?: string, _validToMin?: string,
    _validToMax?: string, _fpaTypeCategory?: string, _category?: string, _externalSystem?: string, _externalId?: string,
    _ORDER?: {COL?: ICol[]}, _FOLDERTYPE?: IFolderType[], _CONTACTS?: {CONTACT?: IContact[]}, _DISTRGROUPS?: {ROW?: IRow[]},
    _USERS?: {ID?: string[]}) {
      super(_server, _session);
      this.timestamp = _timestampe;
      this.offset = _offset;
      this.limit = _limit;
      this.treeHandle = _treeHandle;
      this.person = _person;
      this.company = _company;
      this.industry = _industry;
      this.department = _department;
      this.position = _position;
      this.type = _type;
      this.onlyIds = _onlyIds;
      this.organizationId = _organizationId;
      this.hasFolder = _hasFolder;
      this.showOnlyUc = _showOnlyUc;
      this.name1 = _name1;
      this.name2 = _name2;
      this.name3 = _name3;
      this.RegNumber = _RegNumber;
      this.VATNumber = _VATNumber;
      this.keyId = _keyId;
      this.contains = _contains;
      this.hideRelations = _hideRelations;
      this.hideFolders = _hideFolders;
      this.distributionGroup = _distributionGroup;
      this.project = _project;
      this.showRelated = _showRelated;
      this.contactContact = _contactContact;
      this.onlyUser = _onlyUser;
      this.accessFilter = _accessFilter;
      this.onlyGarant = _onlyGarant;
      this.onlyAvailable = _onlyAvailable;
      this.availableFrom = _availableFrom;
      this.availableTo = _availableTo;
      this.taskType = _taskType;
      this.notSimilarName = _notSimilarName;
      this.viewId = _viewId;
      this.workContract = _workContract;
      this.region = _region;
      this.city = _city;
      this.zip = _zip;
      this.agemin = _agemin;
      this.agemax = _agemax;
      this.countOnly = _countOnly;
      this.folderStatus = _folderStatus;
      this.conjuctionDistrGroups = _conjuctionDistrGroups;
      this.showPersonInfo = _showPersonInfo;
      this.consentStatus = _consentStatus;
      this.consentNote = _consentNote;
      this.anonymized = _anonymized;
      this.validFromMin = _validFromMin;
      this.validFromMax = _validFromMax;
      this.validToMin = _validToMin;
      this.validToMax = _validToMax;
      this.fpaTypeCategory = _fpaTypeCategory;
      this.category = _category;
      this.externalSystem = _externalSystem;
      this.externalId = _externalId;
      this.ORDER = _ORDER;
      this.FOLDERTYPE = _FOLDERTYPE;
      this.CONTACTS = _CONTACTS;
      this.DISTRGROUPS = _DISTRGROUPS;
      this.USERS = _USERS;
    }

  toXml(): string {
    var parameters: string = super.toXml();
    parameters += this.timestamp ? `<timestamp>${this.timestamp}</timestamp>` : '';
    parameters += this.offset ? `<offset>${this.offset}</offset>` : '';
    parameters += this.limit ? `<limit>${this.limit}</limit>` : '';
    parameters += this.treeHandle ? `<treeHandle>${this.treeHandle}</treeHandle>` : '';
    parameters += this.person ? `<person>${this.person}</person>` : '';
    parameters += this.company ? `<company>${this.company}</company>` : '';
    parameters += this.industry ? `<industry>${this.industry}</industry>` : '';
    parameters += this.department ? `<department>${this.department}</department>` : '';
    parameters += this.position ? `<position>${this.position}</position>` : '';
    parameters += this.type ? `<type>${this.type}</type>` : '';
    parameters += this.onlyIds ? `<onlyIds>${this.onlyIds}</onlyIds>` : '';
    parameters += this.organizationId ? `<organizationId>${this.organizationId}</organizationId>` : '';
    parameters += this.hasFolder ? `<hasFolder>${this.hasFolder}</hasFolder>` : '';
    parameters += this.showOnlyUc ? `<showOnlyUc>${this.showOnlyUc}</showOnlyUc>` : '';
    parameters += this.name1 ? `<name1>${this.name1}</name1>` : '';
    parameters += this.name2 ? `<name2>${this.name2}</name2>` : '';
    parameters += this.name3 ? `<name3>${this.name3}</name3>` : '';
    parameters += this.RegNumber ? `<RegNumber>${this.RegNumber}</RegNumber>` : '';
    parameters += this.VATNumber ? `<VATNumber>${this.VATNumber}</VATNumber>` : '';
    parameters += this.keyId ? `<keyId>${this.keyId}</keyId>` : '';
    parameters += this.contains ? `<contains>${this.contains}</contains>` : '';
    parameters += this.hideRelations ? `<hideRelations>${this.hideRelations}</hideRelations>` : '';
    parameters += this.hideFolders ? `<hideFolders>${this.hideFolders}</hideFolders>` : '';
    parameters += this.distributionGroup ? `<distributionGroup>${this.distributionGroup}</distributionGroup>` : '';
    parameters += this.project ? `<project>${this.project}</project>` : '';
    parameters += this.showRelated ? `<showRelated>${this.showRelated}</showRelated>` : '';
    parameters += this.contactContact ? `<contactContact>${this.contactContact}</contactContact>` : '';
    parameters += this.onlyUser ? `<onlyUser>${this.onlyUser}</onlyUser>` : '';
    parameters += this.accessFilter ? `<accessFilter>${this.accessFilter}</accessFilter>` : '';
    parameters += this.onlyGarant ? `<onlyGarant>${this.onlyGarant}</onlyGarant>` : '';
    parameters += this.onlyAvailable ? `<onlyAvailable>${this.onlyAvailable}</onlyAvailable>` : '';
    parameters += this.availableFrom ? `<availableFrom>${this.availableFrom}</availableFrom>` : '';
    parameters += this.availableTo ? `<availableTo>${this.availableTo}</availableTo>` : '';
    parameters += this.taskType ? `<taskType>${this.taskType}</taskType>` : '';
    parameters += this.notSimilarName ? `<notSimilarName>${this.notSimilarName}</notSimilarName>` : '';
    parameters += this.viewId ? `<viewId>${this.viewId}</viewId>` : '';
    parameters += this.workContract ? `<workContract>${this.workContract}</workContract>` : '';
    parameters += this.region ? `<region>${this.region}</region>` : '';
    parameters += this.city ? `<city>${this.city}</city>` : '';
    parameters += this.zip ? `<zip>${this.zip}</zip>` : '';
    parameters += this.agemin ? `<agemin>${this.agemin}</agemin>` : '';
    parameters += this.agemax ? `<agemax>${this.agemax}</agemax>` : '';
    parameters += this.gender ? `<gender>${this.gender}</gender>` : '';
    parameters += this.countOnly ? `<countOnly>${this.countOnly}</countOnly>` : '';
    parameters += this.folderStatus ? `<folderStatus>${this.folderStatus}</folderStatus>` : '';
    parameters += this.conjuctionDistrGroups ? `<conjuctionDistrGroups>${this.conjuctionDistrGroups}</conjuctionDistrGroups>` : '';
    parameters += this.showPersonInfo ? `<showPersonInfo>${this.showPersonInfo}</showPersonInfo>` : '';
    parameters += this.consentStatus ? `<consentStatus>${this.consentStatus}</consentStatus>` : '';
    parameters += this.consentNote ? `<consentNote>${this.consentNote}</consentNote>` : '';
    parameters += this.anonymized ? `<anonymized>${this.anonymized}</anonymized>` : '';
    parameters += this.validFromMin ? `<validFromMin>${this.validFromMin}</validFromMin>` : '';
    parameters += this.validFromMax ? `<validFromMax>${this.validFromMax}</validFromMax>` : '';
    parameters += this.validToMin ? `<validToMin>${this.validToMin}</validToMin>` : '';
    parameters += this.validToMax ? `<validToMax>${this.validToMax}</validToMax>` : '';
    parameters += this.fpaTypeCategory ? `<fpaTypeCategory>${this.fpaTypeCategory}</fpaTypeCategory>` : '';
    parameters += this.category ? `<category>${this.category}</category>` : '';
    parameters += this.externalSystem ? `<externalSystem>${this.externalSystem}</externalSystem>` : '';
    parameters += this.externalId ? `<externalId>${this.externalId}</externalId>` : '';
    parameters += this.ORDER ? `<ORDER>${this.ORDER?.COL?.map((col) => `<COL><name>${col.name}</name><desc>${col.desc}</desc></COL>`).join('')}</ORDER>` : '';
    parameters += this.FOLDERTYPE ? `<FOLDERTYPE>${this.FOLDERTYPE?.map((folderType) => `<value>${folderType.value}</value>`).join('')}</FOLDERTYPE>` : '';
    parameters += this.CONTACTS ? `<CONTACTS>${this.CONTACTS?.CONTACT?.map((contact) => `<CONTACT><content>${contact.content}</content><description>${contact.description}</description><isMain>${contact.isMain}</isMain><isPrimary>${contact.isPrimary}</isPrimary><type>${contact.type}</type><typeName>${contact.typeName}</typeName><typeType>${contact.typeType}</typeType><category>${contact.category}</category></CONTACT>`).join('')}</CONTACTS>` : '';
    parameters += this.DISTRGROUPS ? `<DISTRGROUPS>${this.DISTRGROUPS?.ROW?.map((row) => `<ROW><id>${row.id}</id></ROW>`).join('')}</DISTRGROUPS>` : '';
    parameters += this.USERS ? `<USERS>${this.USERS?.ID?.map((id) => `<ID>${id}</ID>`).join('')}</USERS>` : '';

    return super.wrapperXml("GetContactList", parameters);
  }
}


