import React, { useEffect, useState } from 'react';
import { Flex, Link, ProgressCircle, View } from '@adobe/react-spectrum';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { FPADataFactory } from '../../../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { GetContextPathRequest } from '../../../../../services/soap/project/requests/GetContextPathRequest';
import styles from './ExternalContactsSearch.module.css';

interface TabsComponentProps {
  loadContactData: (typeName: string, id: number) => void;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ loadContactData }) => {
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const { projectService, store } = useDependency();
  const [contextResult, setContextResult] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectTabId, setSelectTabId] = useState<any>('');
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      //console.log('selectedItem', selectedItem?.parent);
      setShowLoader(true);
      const contextRespone = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));

      contextRespone.LIST.forEach((item: any) => {
        //console.log('item', item);
        let result = FPADataFactory.createFromContextItem(item.CONTEXT);
        item.CONTEXT.typeName = result.type;
      });
      //console.log('contextRespone', contextRespone.LIST);
      setContextResult(contextRespone.LIST);
      setShowLoader(false);
    })();
  }, [selectedItem]);

  const truncateText = (text: string, maxLength: number) => {
    return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const handleTabClick = (index: number) => {
    setContextResult((prevContextResult: any) =>
      prevContextResult.map((item: any, i: number) => ({
        ...item,
        isSelected: i === index,
      }))
    );
    setSelectTabId(contextResult[index]?.CONTEXT.id ?? '');

    loadContactData(contextResult[index]?.CONTEXT.typeName, contextResult[index]?.CONTEXT.id);
    //console.log(contextResult[index]?.CONTEXT.typeName);
  };
  return showLoader ? (
    <Flex UNSAFE_className={styles.tabs_container} direction="row" UNSAFE_style={{ overflowY: 'auto' }} justifyContent={'center'}>
      <ProgressCircle aria-label="Loading…" isIndeterminate />
    </Flex>
  ) : (
    <Flex UNSAFE_className={styles.tabs_container} direction="row" UNSAFE_style={{ overflowY: 'auto' }}>
      <Flex UNSAFE_className={styles.tab_chlid}>
        <View UNSAFE_className={`${selectTabId !== '' ? styles.tab_chlid_second : styles.tab_child_selected}`}>
          <Link
            UNSAFE_style={{ display: 'flex', justifyContent: 'center', color: '#36474f' }}
            width={50}
            isQuiet
            onPress={() => {
              handleTabClick(-1);
            }}
          >
            {t('all', { ns: 'layout_components' })}
          </Link>
        </View>
      </Flex>

      {contextResult.map(
        (item: any, index: number) =>
          index !== 0 && ( //don’t show contacts from the same level
            <Flex key={item.CONTEXT.id} UNSAFE_className={`${item.isSelected ? styles.tab_child_selected : styles.tab_chlid_second}`}>
              <View UNSAFE_className={styles.tab_label}>
                <Link
                  isQuiet
                  onPress={() => {
                    handleTabClick(index);
                  }}
                  UNSAFE_style={{ color: '#36474f' }}
                >
                  <span title={item.CONTEXT.name}>{truncateText(item.CONTEXT.name, 10)}</span>
                </Link>
              </View>
            </Flex>
          )
      )}
    </Flex>
  );
};

export default TabsComponent;
