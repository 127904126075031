import * as React from 'react';
import AppMenuGroup from './AppMenuGroup';
import styles from './AppMenuMobile.module.css';
import { AppMenuItemType } from '../AppMenu/AppMenu.types';
import { AppTabContainerItemType } from '../AppTabs/AppTabContainerItem';
import { AppTabContainerMobile } from '../AppTabsMobile/AppTabContainerMobile';

export interface IAppMenuMobileProps {
    groups: any[];
    openedApps: AppTabContainerItemType[];
    onItemClicked: (item:AppMenuItemType) => void;
    selectedApp: string;
    onSelectedAppChanged: (itemKey: string) => void;
}

export function AppMenuMobile ({ 
  groups,
  onItemClicked,
  openedApps,
  selectedApp,
  onSelectedAppChanged
}: IAppMenuMobileProps) {
  return (
    <>
        <div className={styles.AppMenu}>
            <AppTabContainerMobile items={openedApps} selectedApp={selectedApp} onSelectedAppChanged={onSelectedAppChanged} />
            {groups.map((group, index) => (<AppMenuGroup key={index} 
                                            items={group.items}
                                            sectionTitle={group.sectionTitle}
                                            onItemClicked={onItemClicked}
                                            />))}
            <div className={styles.AppMenuFooter} />
        </div>
    </>
  );
}
