import { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex, Content, View, Link, ProgressCircle, DialogContainer, Dialog, TextArea, Header } from '@adobe/react-spectrum';
import ExternalContactsSearch from './component/ExternalContactsSearch';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import ContactCard from './component/ContactCard';
import useComonentReload from '../../../../hooks/UseComponentReload';
import ExternalNewContact from './component/ExternalNewContact';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { UpdateProjectRequest } from '../../../../services/soap/project/requests/UpdateProjectRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { UpdateFolderRequest } from '../../../../services/soap/project/requests/UpdateFolderRequest';
import styles from './ExternalContacts.module.css';

interface ExternalContactsProps {}
type UpdateRequestFunction = () => Promise<{ result: string }>;
function ExternalContacts() {
  const [reloadComponent] = useComonentReload();
  const { projectService, store } = useDependency();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [externalContacts, setExternalContacts] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isOpenSearchContact, setIsOpenSearchContact] = useState<boolean>(false);
  const [isOpenNewContact, setIsOpenNewContact] = useState<boolean>(false);
  const { t } = useTranslation();

  const current_Item = useRef<any>(null);
  useEffect(() => {
    (async () => {
      await loadExternalContacts();
    })();
  }, [selectedItem, reloadComponent]);

  const loadExternalContacts = async () => {
    if (!selectedItem) return;
    setShowLoader(true);
    const setCurrentItemAndContacts = (item: any) => {
      //console.log('item?.EXTERNAL_CONTACTS?.ROWS', item?.EXTERNAL_CONTACTS?.ROWS);
      current_Item.current = item;
      setExternalContacts(item?.EXTERNAL_CONTACTS?.ROWS);
    };

    let item;
    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        item = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, Number(selectedItem.id)));
        setCurrentItemAndContacts(item.ACTIVITY);
        break;
      case FPADataTypes.PROJECT:
        item = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem.id));
        setCurrentItemAndContacts(item.PROJECT);
        break;
      case FPADataTypes.FOLDER:
        item = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem.id));
        setCurrentItemAndContacts(item.FOLDER);
        break;
    }
    setShowLoader(false);
  };

  const updateItem = async (updateRequest: UpdateRequestFunction, successMessage: string) => {
    const updatedItem = await updateRequest();
    if (updatedItem.result === 'OK') {
      ToastQueue.positive(successMessage, { timeout: 3000 });
    }
  };

  const updateContactNote = (contactId: string, description: string) => {
    current_Item.current.EXTERNAL_CONTACTS?.ROWS?.forEach((contact: any) => {
      if (contact.EXTERNAL_CONTACT.id === contactId) {
        contact.EXTERNAL_CONTACT.note = description;
        contact.EXTERNAL_CONTACT.deleteContact = '0';
      } else {
        contact.EXTERNAL_CONTACT.deleteContact = '0';
      }
    });
  };

  const onSaveDescription = async (contactId: string, description: string) => {
    if (!selectedItem) return;

    updateContactNote(contactId, description);

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...current_Item.current,
        EXTERNAL_CONTACTS: current_Item.current.EXTERNAL_CONTACTS,
      },
    };

    let successMessage = 'Description saved successfully';

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
    }
  };

  const updateContactRating = (contactId: string, rating: string) => {
    current_Item.current.EXTERNAL_CONTACTS?.ROWS?.forEach((contact: any) => {
      if (contact.EXTERNAL_CONTACT.id === contactId) {
        contact.EXTERNAL_CONTACT.relationshipLevel = rating;
        contact.EXTERNAL_CONTACT.deleteContact = '0';
      } else {
        contact.EXTERNAL_CONTACT.deleteContact = '0';
      }
    });
  };

  const onSaveRating = async (contactId: string, rating: string) => {
    if (!selectedItem) return;

    updateContactRating(contactId, rating);

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...current_Item.current,
        EXTERNAL_CONTACTS: current_Item.current.EXTERNAL_CONTACTS,
      },
    };
    let successMessage = t('rating_saved_successfully', { ns: 'layout_components' });

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
    }
  };

  const openSearchDialog = () => {
    setIsOpenSearchContact(true);
    setIsOpenNewContact(false);
  };

  const closeSearchDialog = async (reload: boolean) => {
    setIsOpenSearchContact(false);
    reload && (await loadExternalContacts());
  };

  const openNewContactDialog = () => {
    setIsOpenSearchContact(false);
    setIsOpenNewContact(true);
  };

  const closeNewContactDialog = async (reload: boolean) => {
    setIsOpenNewContact(false);
    reload && (await loadExternalContacts());
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('client_contact', { ns: 'layout_components' })}
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  setIsOpenSearchContact(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>{t('add', { ns: 'layout_components' })}</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            {externalContacts?.map((contact: any, index: number) => (
              <ContactCard
                key={contact.EXTERNAL_CONTACT.id}
                contact={contact?.EXTERNAL_CONTACT}
                avatarSrc="../../../../../../assets/images/avatar--desktop--light@2x.png"
                selectedItem={selectedItem}
                FPADataTypes={FPADataTypes}
                onSaveDescription={onSaveDescription}
                onSaveRating={onSaveRating}
                currentItem={current_Item}
                updateItem={updateItem}
                closeDialog={closeNewContactDialog}
              />
            ))}
          </Flex>

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsOpenSearchContact(false);
            }}
          >
            {isOpenSearchContact && (
              <Dialog>
                <Header UNSAFE_className={styles.add_contact_heading}>{t('add_client_contact', { ns: 'layout_components' })}</Header>
                <Content>
                  <ExternalContactsSearch closeDialog={closeSearchDialog} openDialog={openNewContactDialog} currentItem={current_Item} selectedItem={selectedItem} />
                </Content>
              </Dialog>
            )}
          </DialogContainer>

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsOpenNewContact(false);
            }}
          >
            {isOpenNewContact && (
              <ExternalNewContact
                selectedItem={selectedItem}
                currentItem={current_Item}
                FPADataTypes={FPADataTypes}
                updateItem={updateItem}
                closeDialog={closeNewContactDialog}
                openDialog={openSearchDialog}
              />
            )}
          </DialogContainer>
        </Flex>
      </Flex>
    );
  }
}

export const ExternalContactsComponent = LayoutComponent(ExternalContacts);
