import * as React from 'react';
import { ILayoutComponentProps } from './LayoutComponent';
import { FallbackComponent } from './Fallback/FallbackComponent';
import { LayoutDefinition } from '../../../infra/protected/Layout/LayoutDefinitions';
import { TabsLayoutComponent } from './TabsLayout/TabsLayoutComponent';
import { HorizontalLayoutComponent } from './HorizontalLayout/HorizontalLayoutComponent';
import { VerticalLayoutComponent } from './VerticalLayout/VerticalLayoutComponent';
import { BasicLayoutComponent } from './BasicLayout/BasicLayoutComponent';
import { SystemAttributes } from '../../../infra/protected/Layout/SystemAttributes';

export interface IComponentFactoryProps extends ILayoutComponentProps {
    definition: LayoutDefinition | null;
    systemAttributes: SystemAttributes<any>;
}

export function ComponentFactory ({ definition, systemAttributes }: IComponentFactoryProps) {
    if(!definition) return (<>No definition</>);

    switch (definition.type) {
        case "tabsLayout":
            return (
                <TabsLayoutComponent {...definition.layoutAttributes }>
                    {definition.children}
                </TabsLayoutComponent>
            );
        case "horizontalLayout":
            return (
                <HorizontalLayoutComponent {...definition.layoutAttributes }>
                    {definition.children}
                </HorizontalLayoutComponent>
            );
        case "verticalLayout":
            return (
                <VerticalLayoutComponent {...definition.layoutAttributes }>
                    {definition.children}
                </VerticalLayoutComponent>
            );
        case "basicLayout":
            var new_props = { 
                ...definition.layoutAttributes, 
                viewComponent: definition.viewComponent, 
                componentAttributes: definition.componentAttributes
            };
            return (
                <BasicLayoutComponent {...systemAttributes}  {...new_props} />
            );
        default:
            return (
                <FallbackComponent {...definition.layoutAttributes} />
            );
    }
}
