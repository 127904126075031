import { FunctionComponent } from "react";
import styles from "./AppMenuGroupItem.module.css";
import { AppMenuItemType } from "./AppMenu.types";

type AppMenuGroupItemType = {
    item: AppMenuItemType;
    onAppClicked: (item:AppMenuItemType) => void;
    isSelectedItem: boolean;
    /** Style props */
};

const AppMenuGroupItem: FunctionComponent<AppMenuGroupItemType> = ({
    item,
    onAppClicked,
    isSelectedItem
  }) => {
    return (<>
        <div className={isSelectedItem ? styles.groupItemSelected : styles.groupItem} onClick={() => onAppClicked(item)}>
          <img className={styles.appIcon} alt="" src={item.itemIcon} />
          <div className={styles.finder}>
            {item.itemTitle}
          </div>
        </div>
    </>);
  }
export default AppMenuGroupItem;