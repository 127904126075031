import { Flex, View } from '@adobe/react-spectrum';
import styles from './AppTabContainerItemMobile.module.css';
import { AppTabContainerItemType } from '../AppTabs/AppTabContainerItem';
import ConstantUtils from '../../../utils/ConstantUtils';

export interface IAppTabContainerItemMobileProps {
    item: AppTabContainerItemType;
    is_selected: boolean;
    onSelectedAppChanged: (itemKey: string) => void;
}

export function AppTabContainerItemMobile ( { 
  item,
  is_selected,
  onSelectedAppChanged
}: IAppTabContainerItemMobileProps) {

  var display_title = item.altName ? item.altName : item.label;
  if(display_title.length > ConstantUtils.FINDER_TITLE_MAX_LENGTH){
      display_title = display_title.substring(0, ConstantUtils.FINDER_TITLE_MAX_LENGTH) + '...';
  }

  return (
    <>
      <span onClick={() => onSelectedAppChanged(item.itemKey)}>
        <Flex direction="column" wrap="nowrap" UNSAFE_className={is_selected ? styles.itemHolderActive : styles.itemHolder} alignItems='center' justifyContent='center' >
          <View>
            <img className={styles.appIcon} alt="" src={item.icon} />
          </View>
          <View UNSAFE_className={styles.title}>
          {display_title}
          </View>
        </Flex>
      </span>
    </>
  );
}
