import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppInfo {
    key: string;
    data: any;
}
interface UserWebLayoutState {
    appInfos: any;
}

const initialState: UserWebLayoutState = {
    appInfos: {}
};

const userWebLayoutSlice = createSlice({
    name: 'userWebLayout',
    initialState,
    reducers: {
        addAppInfo(state, action: PayloadAction<string>) {
            state.appInfos[action.payload] = { };
        },
        removeAppInfo(state, action: PayloadAction<string>) {
            state.appInfos[action.payload] = undefined;
        },
        updateAppInfo(state, action: PayloadAction<AppInfo>) {
            state.appInfos[action.payload.key] = action.payload.data;
        }
    },
});

export const { 
    addAppInfo, 
    removeAppInfo ,
    updateAppInfo
} = userWebLayoutSlice.actions;

export default userWebLayoutSlice.reducer;
