import { Provider, defaultTheme } from '@adobe/react-spectrum';
import { ToastContainer, ToastQueue } from '@react-spectrum/toast';
import PublicRoutes from './routes/PublicRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import { useLocation } from 'react-router-dom';
import { DependencyProvider } from './contexts/DependencyProvider';
import { UsePoller } from './hooks/UsePoller';
import { useEffect } from 'react';
import './App.css';
import { useReefConfig } from './hooks/UseReefConfig';

function App() {
  const { state } = useLocation();
  const { baseUrl } = useReefConfig();
  const { isNewVersionAvailable } = UsePoller({ deploymentUrl: `${baseUrl}/index.html`, comparisonInterval: 1000 * 60 });

  useEffect(() => {
    if (isNewVersionAvailable) {
      var message: string = 'A new version of the application is available. Please restart the application to complete the upgrade.';
      ToastQueue.info(message, { timeout: 10000 });
    }
  }, [isNewVersionAvailable]);

  return (
    <DependencyProvider>
      <Provider theme={defaultTheme} colorScheme="light">
        <ToastContainer />
        {(!state || !state.session) && <PublicRoutes />}
        {state && state.session && <ProtectedRoutes />}
      </Provider>
    </DependencyProvider>
  );
}

export default App;
