import React, { useEffect, useState } from 'react';
import { ActionButton, Checkbox, ComboBox, Content, DatePicker, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, View } from '@adobe/react-spectrum';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useTranslation } from 'react-i18next';
import { useBeforeRender } from '../../../../hooks/useBeforeRender';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { RowData } from './TimesheetLineComponent';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { ListFPARequest, Parent } from '../../../../services/soap/project/requests/ListFPARequest';
import { parseDate } from '@internationalized/date';
import moment from 'moment';
import { Provider } from '@adobe/react-spectrum';
interface TimesheetLineAddEditProps {
  store: any;
  projectService: any;
  selectedItem: any;
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  typeofWorkList: any[];
  userData: any[];
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  closeDialog: (isOpen: boolean) => void;
  styles: any;
}

const TimesheetLineAddEdit: React.FC<TimesheetLineAddEditProps> = ({
  store,
  projectService,
  selectedItem,
  id,
  defaultRowData,
  selectedRowData,
  typeofWorkList,
  userData,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  closeDialog,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);
  const [projectList, setProjectList] = useState<any>([]);

  const [validationMessages, setValidationMessages] = useState<any>({ projectId: '', workerName: '', typeOfWork: '', entryDate: '', hours: '', mintues: '', chargeable: '' });

  /** Code to hide Observer Error */
  useBeforeRender(() => {
    window.addEventListener('error', e => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  useEffect(() => {
    const isDefaultRow = selectedRowData.ident === 0;

    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        setProjectList([]);
        break;
      case FPADataTypes.PROJECT:
        setProjectList([]);
        break;
      case FPADataTypes.FOLDER:
        loadProjectList();
        break;
    }
    setValidationMessages({
      projectId: isDefaultRow && isProjectLevel() ? 'required' : '',
      workerName: '',
      typeOfWork: isDefaultRow ? 'required' : '',
      entryDate: '',
      hours: '',
      mintues: '',
      chargeable: '',
    });

    //console.log('selectedRowData', selectedRowData);

    setSelectedRow(selectedRowData || defaultRowData);
    if (isDefaultRow) setSelectedRow(prev => ({ ...prev, hours: '1', minutes: '0' }));
  }, [id, selectedRowData]);

  let loadProjectList = async () => {
    let request = new ListFPARequest(
      store.Server,
      store.SessionId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      false,
      new Parent(Number(selectedItem?.id), 'FOLDER'),
      undefined,
      undefined,
      undefined,
      undefined
    );
    let result = await projectService.listFPA(request);
    // Extract project ID and name
    const projects = result.ITEMS.map((item: any) => ({
      id: item.ITEM.PROJECT.id,
      name: item.ITEM.PROJECT.name,
    }));
    setProjectList(projects);
    //console.log('PROJECT LIST', result);
  };

  const isProjectLevel = () => {
    let flag = false;
    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        flag = false;
        break;
      case FPADataTypes.PROJECT:
        flag = false;
        break;
      case FPADataTypes.FOLDER:
        flag = true;
        break;
    }

    return flag;
  };

  const validateFields = () => {
    //console.log('validationMessages ', validationMessages);
    const fields = [
      { key: 'workerName', value: selectedRow.userId },
      { key: 'typeOfWork', value: selectedRow.typeOfWorkId },
      { key: 'entryDate', value: selectedRow.entryDate },
      { key: 'hours', value: selectedRow.hours },
    ];

    const isValid = fields.every(({ key, value }) => {
      const isFieldValid = validationMessages[key] === '' && value !== '' && value !== undefined;
      if (!isFieldValid) setValidationMessages((prev: any) => ({ ...prev, [key]: 'required' }));
      return isFieldValid;
    });

    return isValid;
  };

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.ident) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow); // Optional: Only if needed for both cases
    action(selectedRow);
  };

  const TimeSheetDialog = ({ close }: { close: () => void }) => (
    <Dialog size="S">
      <Flex UNSAFE_className={styles.delete_pop_up_parent}>
        <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_time', { ns: 'layout_components' })}</Content>
        <View UNSAFE_className={styles.delete_pop_up_line}></View>
        <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('are_you_sure_you_want_to_remove_time', { ns: 'layout_components' })}</View>
        <View UNSAFE_className={styles.buttons_parent}>
          <View UNSAFE_className={styles.button_cancel}>
            <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
              {t('no_keep_it', { ns: 'layout_components' })}
            </Link>
          </View>
          <View UNSAFE_className={styles.button_confirm}>
            <div className={styles.trash_icon}>
              <TrashIcon size={32} />
            </div>
            <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
              {t('yes_remove', { ns: 'layout_components' })}
            </Link>
          </View>
        </View>
        <View UNSAFE_className={styles.ellipse_div}>
          <View UNSAFE_className={styles.delete_alert_icon}>
            <AlertIconFill size={72} />
          </View>
        </View>
      </Flex>
    </Dialog>
  );

  const RemoveTimeSheetButton = () =>
    Number(selectedRowData.ident) !== 0 && (
      <Flex marginTop={isMobile ? '10px' : '0'}>
        <DialogTrigger isDismissable type="modal">
          <ActionButton UNSAFE_className={styles.btn_delete}>{t('remove_time', { ns: 'layout_components' })}</ActionButton>
          {close => <TimeSheetDialog close={close} />}
        </DialogTrigger>
      </Flex>
    );

  const TimeSheetActions = () => (
    <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
      <Flex>
        <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex>
      <Flex>
        <Link width={'180px'} UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
          {selectedRowData.ident === 0 ? t('add', { ns: 'layout_components' }) : t('edit', { ns: 'layout_components' })}
        </Link>
      </Flex>
    </Flex>
  );

  const TimeSheetButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'} marginTop={isMobile ? '0' : 'size-100'}>
      <Flex>
        <RemoveTimeSheetButton />
      </Flex>
      <TimeSheetActions />
    </Flex>
  );

  return (
    <>
      <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'} UNSAFE_style={{ overflowY: 'auto' }}>
        {isMobile ? (
          <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
            <View>
              <ComboBox
                label={t('worker', { ns: 'layout_components' })}
                isRequired
                width={'100%'}
                marginTop={'20px'}
                defaultItems={userData}
                selectedKey={selectedRow.userId}
                onSelectionChange={value => {
                  const worker = userData.find((item: any) => item.id === value);
                  if (worker?.name) setSelectedRow((prev: any) => ({ ...prev, userId: value, workerName: worker.name }));
                }}
                validationState={validationMessages.workerName !== '' ? 'invalid' : 'valid'}
              >
                {(item: any) => <Item key={item.id}>{item.name}</Item>}
              </ComboBox>
            </View>
            {isProjectLevel() && (
              <View>
                <Picker
                  label={t('project', { ns: 'layout_components' })}
                  isRequired
                  width={'100%'}
                  items={projectList}
                  onSelectionChange={value => {
                    setValidationMessages((prev: any) => ({ ...prev, projectId: '' }));
                    setSelectedRow((prev: any) => ({ ...prev, projectId: value }));
                  }}
                  selectedKey={selectedRow.projectId}
                  validationState={validationMessages.projectId !== '' ? 'invalid' : 'valid'}
                >
                  {(item: any) => <Item key={item.id}>{item.name}</Item>}
                </Picker>
              </View>
            )}

            <View>
              <Picker
                label={t('type_of_work', { ns: 'layout_components' })}
                isRequired
                width={'100%'}
                items={typeofWorkList}
                onSelectionChange={value => {
                  setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                  const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                  setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
                }}
                selectedKey={selectedRow.typeOfWorkId}
                validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
              >
                {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
              </Picker>
            </View>
            <View>
              <Provider locale="en-US">
                <DatePicker
                  label={t('date', { ns: 'layout_components' })}
                  UNSAFE_style={{ fontWeight: '400' }}
                  isRequired
                  width={'100%'}
                  value={parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                  onChange={(value: any) => {
                    setSelectedRow(prev => ({ ...prev, entryDate: value }));
                  }}
                />
              </Provider>
            </View>
            <View>
              <NumberField
                width={'100%'}
                value={Number(selectedRow.hours)}
                defaultValue={Number(selectedRow.hours)}
                isRequired
                minValue={1}
                label={t('hours', { ns: 'layout_components' })}
                onChange={value => {
                  const message = isNaN(value) || value <= 0 ? 'Hours must be greater than 0' : '';
                  setValidationMessages((prev: any) => ({ ...prev, hours: message }));
                  setSelectedRow(prev => ({ ...prev, hours: String(value) }));
                }}
                validationState={validationMessages.hours !== '' ? 'invalid' : 'valid'}
              />
              <NumberField
                width={'100%'}
                value={Number(selectedRow.minutes)}
                step={5}
                isRequired
                minValue={0}
                maxValue={60}
                label={t('minutes', { ns: 'layout_components' })}
                onChange={value => {
                  const message = isNaN(value) || value <= 0 ? 'Minutes must be greater than 0' : '';
                  setValidationMessages((prev: any) => ({ ...prev, minutes: message }));
                  if (value > 59) {
                    setSelectedRow(prev => ({ ...prev, minutes: '0', hours: String(Number(prev.hours) + 1) }));
                  } else setSelectedRow(prev => ({ ...prev, minutes: String(value) }));
                }}
                validationState={validationMessages.hours !== '' ? 'invalid' : 'valid'}
              />
            </View>
            <View>
              <Checkbox isSelected={selectedRow.chargeable === 'Yes'} onChange={value => setSelectedRow(prev => ({ ...prev, chargeable: value ? 'Yes' : 'No' }))}>
                <label className="A-HlBa_spectrum-FieldLabel">{t('chargeable', { ns: 'layout_components' })}</label>
              </Checkbox>
            </View>
          </Flex>
        ) : (
          <table className="spectrum-Table" style={{ marginTop: '20px' }}>
            <thead className="spectrum-Table-head">
              <tr>
                <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <ComboBox
                      width={'100%'}
                      label=""
                      defaultItems={userData}
                      selectedKey={selectedRow.userId}
                      onSelectionChange={value => {
                        const worker = userData.find((item: any) => item.id === value);
                        if (worker?.name) setSelectedRow((prev: any) => ({ ...prev, userId: value, workerName: worker.name }));
                      }}
                      validationState={validationMessages.workerName !== '' ? 'invalid' : 'valid'}
                    >
                      {(item: any) => <Item key={item.id}>{item.name}</Item>}
                    </ComboBox>
                  </span>
                </th>
                <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    {isProjectLevel() && (
                      <>
                        <Picker
                          label=""
                          width={'100%'}
                          items={projectList}
                          onSelectionChange={value => {
                            setValidationMessages((prev: any) => ({ ...prev, projectId: '' }));
                            setSelectedRow((prev: any) => ({ ...prev, projectId: value }));
                          }}
                          selectedKey={selectedRow.projectId}
                          validationState={validationMessages.projectId !== '' ? 'invalid' : 'valid'}
                        >
                          {(item: any) => <Item key={item.id}>{item.name}</Item>}
                        </Picker>
                        <hr />
                      </>
                    )}
                    <Picker
                      label=""
                      width={'100%'}
                      items={typeofWorkList}
                      onSelectionChange={value => {
                        setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                        const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                        setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
                      }}
                      selectedKey={selectedRow.typeOfWorkId}
                      validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
                    >
                      {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
                    </Picker>
                  </span>
                </th>
                <th style={{ width: '20%' }} className="spectrum-Table-headCell date_bg_fix">
                  <Provider locale="en-US">
                    <DatePicker
                      UNSAFE_style={{ fontWeight: '400' }}
                      isRequired
                      width={'100%'}
                      value={parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                      onChange={(value: any) => {
                        console.log('value', parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD')));
                        setSelectedRow(prev => ({ ...prev, entryDate: value }));
                      }}
                    />
                  </Provider>
                </th>
                <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <table width={'100%'}>
                      <tbody>
                        <tr>
                          <td width={'55%'} title="Enter Hours">
                            <NumberField
                              width={'100%'}
                              value={Number(selectedRow.hours)}
                              hideStepper
                              isRequired
                              minValue={1}
                              label=""
                              onChange={value => {
                                const message = isNaN(value) || value <= 0 ? 'Hours must be greater than 0' : '';
                                setValidationMessages((prev: any) => ({ ...prev, hours: message }));
                                setSelectedRow(prev => ({ ...prev, hours: String(value) }));
                              }}
                              validationState={validationMessages.hours !== '' ? 'invalid' : 'valid'}
                            />
                          </td>
                          <td title="Enter Minutes">
                            <NumberField
                              width={'100%'}
                              value={Number(selectedRow.minutes)}
                              hideStepper
                              isRequired
                              minValue={0}
                              maxValue={60}
                              label=""
                              onChange={value => {
                                const message = isNaN(value) || value <= 0 ? 'Minutes must be greater than 0' : '';
                                setValidationMessages((prev: any) => ({ ...prev, minutes: message }));
                                if (value > 59) {
                                  setSelectedRow(prev => ({ ...prev, minutes: '0', hours: String(Number(prev.hours) + 1) }));
                                } else setSelectedRow(prev => ({ ...prev, minutes: String(value) }));
                              }}
                              validationState={validationMessages.mintues !== '' ? 'invalid' : 'valid'}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                </th>
                <th style={{ width: '9%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <Checkbox isSelected={selectedRow.chargeable === 'Yes'} onChange={value => setSelectedRow(prev => ({ ...prev, chargeable: value ? 'Yes' : 'No' }))} marginStart={'size-125'} />
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        )}
        <TimeSheetButtons />
      </Flex>
    </>
  );
};

export default TimesheetLineAddEdit;
