import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';

const validUrls = [
    '/finder',
    '/recruitment-dashboard',
];
export interface IMiddleInterceptorProps {
}

export function MiddleInterceptor (props: IMiddleInterceptorProps) {
    
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    React.useEffect(() => {
        if(validUrls.includes(pathname)){ 
            navigate('/', { state: { redirect_url:pathname + search}});
        } else
            navigate('/error404');
    }, [pathname, search]);
  return (
    <>Interceptor {pathname} :: {search}</>
  );
}
