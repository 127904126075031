import { Route, Routes } from "react-router-dom";
// import Register from "../pages/public/Register";
import Login from "../pages/public/Login";
// import Tester from "../pages/public/Tester";
import WebLayout from "../layouts/public/WebLayout";
import '../assets/styles/public.css';
import { MiddleInterceptor } from "../components/public/Interceptor/MiddleInterceptor";
const PublicRoutes = () => {
    return ( <>
    <Routes>
        <Route element={<WebLayout />}>
            <Route path="/" element={<Login />} />
            {/* <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/tester" element={<Tester />} /> */}
            <Route path="/error404" element={<h1>404 - Not Found</h1>} />
            <Route path="*" element={<MiddleInterceptor />} />
        </Route>
    </Routes>
    </> );
}
 
export default PublicRoutes;