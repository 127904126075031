import { ActionButton, Flex, Item, Menu, MenuTrigger, Selection } from '@adobe/react-spectrum';
import { AppTabContainerItem, AppTabContainerItemType } from './AppTabContainerItem';
import styles from './AppTabContainer.module.css';
import { useEffect, useState } from 'react';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';

export interface IAppTabContainerProps {
    items: AppTabContainerItemType[];
    closeApp: (item: string) => void;
    selected_app: string;
    onSelectedAppChanged: (itemKey: string) => void;
    swapOpenItemLocations: (itemKey1: string, itemKey2: string) => void;
    areaWidth: number;
}

export function AppTabContainer ({
    items,
    closeApp,
    selected_app,
    onSelectedAppChanged,
    areaWidth,
    swapOpenItemLocations
}: IAppTabContainerProps) {
    const [selectedOverFlowItem, setSelectedOverFlowItem] = useState<Selection>(new Set([selected_app]));
    const AVG_ITEM_WIDTH = 180;
    const isOverflown = items.length * AVG_ITEM_WIDTH > areaWidth;
    const max_items = Math.floor(areaWidth / AVG_ITEM_WIDTH);

    const handleItemSelection = (item: Selection) => {
        swapOpenItemLocations(items[max_items-1].itemKey, item.currentKey);
    }

    useEffect(() => {
        setSelectedOverFlowItem(new Set([selected_app]));
        if(!items || !items[max_items-1]) {
            // console.log('items or items[max_items-1] is null');
            // console.log('items:', items, 'max_items:', max_items, 'selected_app:', selected_app, 'items[max_items-1]:', items[max_items-1]);
            return;
        }
        if(max_items < items.length && items[max_items-1].itemKey !== selected_app)
            swapOpenItemLocations(items[max_items-1].itemKey, selected_app);
    }, [selected_app]);

   
        return (
            <>
                <Flex direction="row" wrap="nowrap" gap="size-100" marginStart={10} UNSAFE_className={styles.tab_container}>
                    {items.slice(0,Math.min(max_items,items.length)).map((item, index) => (
                        <AppTabContainerItem key={index} 
                                item={item} 
                                closeApp={closeApp} 
                                is_selected={item.itemKey === selected_app} 
                                onSelectedAppChanged={onSelectedAppChanged}
                                />
                    ))}
                    {isOverflown && (<MenuTrigger>
                        <ActionButton>
                            <ChevronDown size="S" />
                        </ActionButton>
                        <Menu 
                            items={items.slice(max_items).map((item, index) => ({key: item.itemKey, label: item.label}))}
                            selectionMode='single'
                            selectedKeys={selectedOverFlowItem}
                            onSelectionChange={handleItemSelection}>
                                { item => <Item key={item.key}>{item.label}</Item> }
                        </Menu>
                    </MenuTrigger>)}
                </Flex>
            </>
        );
}
