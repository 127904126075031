export const ListRegisterUtils = {
    Modules:{
        Finder: 'com.atollon.core.finder',
        RctDashboard: 'com.atollon.core.project',
    },
    Keys:{
        Finder: {
            FinderSettings: 'FinderSettings',
        },
        RctDashboard: {
            FilterSettings: 'Reef.FilterSettings'
        }
    },
    Levels:{
        RctDashboard:{
            Default: 40,
        }
    },
}