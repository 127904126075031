import { Flex, View } from '@adobe/react-spectrum';
import styles from './Dashboard.module.css';
import jsonPackage from '../../../package.json';
import { useDependency } from '../../contexts/DependencyProvider';
import { useTranslation } from 'react-i18next';

import { ListContactListRequest } from '../../services/soap/main/requests/ListContactListRequest';
import { GetFromContactListRequest } from '../../services/soap/main/requests/GetFromContactListRequest';
import { ListCountryRequest } from '../../services/soap/translate/reqeusts/ListCountryRequest';
import { ListActivityUserRequest } from '../../services/soap/team/requests/ListActivityUserRequest';
import { NewActivityUserRequest } from '../../services/soap/team/requests/NewActivityUserRequest';
import { GetContactListRequest } from '../../services/soap/main/requests/GetContactListRequest';
import { ListProjectUserRequest } from '../../services/soap/team/requests/ListProjectUserRequest';
import { ListFolderUserRequest } from '../../services/soap/team/requests/ListFolderUserRequest';
import { UpdateActivityUserRequest } from '../../services/soap/team/requests/UpdateActivityUserRequest';
import { DeleteActivityUserRequest } from '../../services/soap/team/requests/DeleteActivityUserRequest';
import { UpdateFolderUserRequest } from '../../services/soap/team/requests/UpdateFolderUserRequest';
import { ListTimelineRequest } from '../../services/soap/features/requests/ListTimelineRequest';
import { off } from 'process';
import { ListActivityTypeRequest } from '../../services/soap/project/requests/ListActivityTypeRequest';
import { NewMessageRequest } from '../../services/soap/message/requests/NewMessageRequest';
import { CheckWorkFlowQueueRequest } from '../../services/soap/features/requests/CheckWorkFlowQueueRequest';

const Dashboard = () => {
    const { 
      mainService, 
      featureService, 
      translateService, 
      projectService, 
      formService,
      itemService, 
      financeService,
      globalService,
      schedulerService,
      accessService,
      printRestService,
      documentLibraryService,
      teamService,
      messageService,
      store 
    } = useDependency();
    const { t } = useTranslation();

    const _t = (key: string) => t( `dashboard.${key}`, { ns: 'common' });
    const today = new Date();

    const folderId = 524524101;
    const projectId = 524587101;

    const handleClick = async () => {
      try {
        var res1 = await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
        console.log('res1', res1);
        
      } catch (error) {
        console.log('error', error);
      }
    };
    return ( <>
        
        <div className={styles.centerText} onClick={handleClick}>
            { _t('welcome') }
        </div>
        <Flex direction="column" alignItems="center">
              <View>
                <p style={{ paddingTop: '10px' }}>
                  v{jsonPackage.version} {_t('copyright')} {today.getFullYear()}
                </p>
              </View>
        </Flex>
    </> );
}
 
export default Dashboard;