import styles from './AppTabContainerMobile.module.css';
import { AppTabContainerItemType } from '../AppTabs/AppTabContainerItem';
import { Flex, View } from '@adobe/react-spectrum';
import { AppTabContainerItemMobile } from './AppTabContainerItemMobile';

export interface IAppTabContainerMobileProps {
    items: AppTabContainerItemType[];
    selectedApp: string;
    onSelectedAppChanged: (itemKey: string) => void;
}

export function AppTabContainerMobile ({
    items,
    selectedApp,
    onSelectedAppChanged
}: IAppTabContainerMobileProps) {

    if(!items || items.length === 0) return null;

    return (
        <>
            
            <Flex direction="column" width={'single-line-width'} wrap="wrap" gap="size-100" marginBottom={20}>
                <View>
                    <span className={styles.Title}>Currently opened</span>
                </View>
                <div className={styles.rowContainer}>
                    <Flex direction='row' wrap="nowrap" gap='size-100' UNSAFE_className={styles.fullItemsView}>
                        {items.map((item, index) => (
                            <AppTabContainerItemMobile key={index} 
                                item={item} 
                                is_selected={item.itemKey === selectedApp}
                                onSelectedAppChanged={onSelectedAppChanged}
                                />
                        ))}
                    </Flex>
                </div>
            </Flex>
        </>
    );
}
