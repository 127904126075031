import { useSelector } from 'react-redux';
import { Button, ComboBox, DatePicker, Flex, Form, Item, Key, TextField, View } from '@adobe/react-spectrum';
import { CalendarDate, parseDate } from '@internationalized/date';
import { RootState } from '../../../../state/store';
import { LayoutComponent } from '../LayoutComponent';
import { useTranslation } from 'react-i18next';
import { useFPA } from '../../../../hooks/UseFPA';
import { FPADataTypes, getMainTypeFromSystemType } from '../../../../infra/protected/FPA/FPAData';
import { useEffect, useRef, useState } from 'react';
import { ListItem, useServiceActivityHeaderComponent } from './ServiceActivityHeaderComponent.hooks';
import { SoapUtils } from '../../../../utils/SoapUtils';
import moment from 'moment';

export interface IServiceActivityHeaderProps {
  endDateTimeTitle?: string;
  firstContactDateTimeTitle?: string;
  isActionVisible?: boolean;
  isEndDateTimeVisible?: boolean;
  isFirstContactDateTimeVisible?: boolean;
  isInvoiceToVisible?: boolean;
  isLocationVisible?: boolean;
  isStartDateTimeVisible?: boolean;
  isSupplierVisible?: boolean;
  startDateTimeTitle?: string;
}

function ServiceActivityHeader({
  endDateTimeTitle,
  firstContactDateTimeTitle,
  isActionVisible,
  isEndDateTimeVisible,
  isFirstContactDateTimeVisible,
  isInvoiceToVisible,
  isLocationVisible,
  isStartDateTimeVisible,
  isSupplierVisible,
  startDateTimeTitle,
}: IServiceActivityHeaderProps) {
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [invoiceToList, setInvoiceToList] = useState<ListItem[]>([]);
  const [suppliersList, setSuppliersList] = useState<ListItem[]>([]);
  const [invoiceTo, setInvoiceTo] = useState('');
  const [supplier, setSupplier] = useState('');
  const [startDate, setStartDate] = useState<CalendarDate | null | undefined>(null);
  const [finalDate, setFinalDate] = useState<CalendarDate | null | undefined>(null);
  const [firstContactDate, setFirstContactDate] = useState<CalendarDate | null | undefined>(null);
  const { get, update } = useFPA(selectedItem?.type || FPADataTypes.NONE);
  const { getSuppliersList, getInvoiceToList, getContext } = useServiceActivityHeaderComponent();
  const current_item_ref = useRef<any>(null);

  // supplier - provider
  // invoice_to - customer
  // startDateTime - startDate
  // endDateTime - finalDate
  // firstContactDateTime - firstContactDate

  const { t } = useTranslation();

  const _t = (key: string) => t(`serviceActivityHeader.${key}`, { ns: 'layout-components' });

  useEffect(() => {
    get(selectedItem?.id || 0).then(data => {
      //console.log('loaded for service activity header: ', data);
      current_item_ref.current = data;
      if (data.provider) {
        getContext(data.provider).then(context => {
          setSuppliersList([{ id: context.id, name: context.name, type: getMainTypeFromSystemType(context.type) }]);
          setSupplier(data.provider);
        });
      }

      if (data.customer) {
        //console.log('data.customer: ', data.customer);
        getContext(data.customer).then(context => {
          //console.log('context: ', context);
          setInvoiceToList([{ id: context.id, name: context.name, type: getMainTypeFromSystemType(context.type) }]);
          setInvoiceTo(data.customer);
        });
      }

      if (data.startDate) setStartDate(parseDate(moment(data.startDate).format('YYYY-MM-DD')));
      if (data.finalDate) setFinalDate(parseDate(moment(data.finalDate).format('YYYY-MM-DD')));
      if (data.firstContactDate) setFirstContactDate(parseDate(moment(data.firstContactDate).format('YYYY-MM-DD')));
      //console.log('data: ', data);
    });
  }, []);

  const handleSupplierInputChange = async (searchText: string) => {
    if (searchText.length < 3) return;
    var list = await getSuppliersList(searchText);
    setSuppliersList(list);
  };

  const handleInvoiceToInputChange = async (searchText: string) => {
    if (searchText.length < 3) return;
    var list = await getInvoiceToList(searchText);
    setInvoiceToList(list);
  };

  const handleSupplierSelectionChange = async (item: Key | null) => {
    setSupplier(item as string);
    await update({ ...current_item_ref.current, provider: item });
  };

  const handleInvoiceToSelectionChange = async (item: Key | null) => {
    setInvoiceTo(item as string);
    await update({ ...current_item_ref.current, customer: item });
  };

  const handleStartDateChange = async (date: CalendarDate) => {
    if (date == startDate) return;
    setStartDate(date);
    await update({ ...current_item_ref.current, startDate: date == null ? null : date.toString() });
  };

  const handleFinalDateChange = async (date: CalendarDate) => {
    if (date == finalDate) return;
    setFinalDate(date);
    await update({ ...current_item_ref.current, finalDate: date == null ? null : date.toString() + 'T00:00:00' });
  };

  const handleFirstContactDateChange = async (date: CalendarDate) => {
    if (date == firstContactDate) return;
    setFirstContactDate(date);
    await update({ ...current_item_ref.current, firstContactDate: date == null ? null : date.toString() + 'T00:00:00' });
  };
  return (
    <>
      <Form>
        <Flex direction={'column'} gap={10}>
          <Flex direction={{ M: 'row', base: 'column' }} gap={10}>
            {isSupplierVisible && (
              <View flexGrow={1}>
                <ComboBox
                  label={_t('supplier')}
                  items={suppliersList}
                  onInputChange={handleSupplierInputChange}
                  width={'100%'}
                  selectedKey={supplier}
                  onSelectionChange={handleSupplierSelectionChange}
                >
                  {item => <Item key={item.id}>{item.name}</Item>}
                </ComboBox>
              </View>
            )}
            {isInvoiceToVisible && (
              <View flexGrow={1}>
                <ComboBox
                  label={_t('invoice_to')}
                  items={invoiceToList}
                  onInputChange={handleInvoiceToInputChange}
                  selectedKey={invoiceTo}
                  onSelectionChange={handleInvoiceToSelectionChange}
                  width={'100%'}
                >
                  {item => <Item key={item.id}>{item.name}</Item>}
                </ComboBox>
              </View>
            )}
          </Flex>
          <Flex direction={{ M: 'row', base: 'column' }} gap={10}>
            {isStartDateTimeVisible && (
              <View flexGrow={1}>
                <DatePicker label={startDateTimeTitle} width={'100%'} value={startDate} onChange={handleStartDateChange} />
              </View>
            )}
            {isEndDateTimeVisible && (
              <View flexGrow={1}>
                <DatePicker label={endDateTimeTitle} width={'100%'} value={finalDate} onChange={handleFinalDateChange} />
              </View>
            )}
          </Flex>
          <Flex direction={{ M: 'row', base: 'column' }} gap={10}>
            {isFirstContactDateTimeVisible && (
              <View flexGrow={1}>
                <DatePicker label={firstContactDateTimeTitle} width={'100%'} value={firstContactDate} onChange={handleFirstContactDateChange} />
              </View>
            )}
            {isLocationVisible && (
              <View flexGrow={1}>
                <TextField label={_t('location')} width="100%" />
              </View>
            )}
          </Flex>
          {isActionVisible && (
            <View>
              <Button variant="cta" width={'100%'}>
                {_t('button.create_event')}
              </Button>
            </View>
          )}
        </Flex>
      </Form>
    </>
  );
}

export const ServiceActivityHeaderComponent = LayoutComponent(ServiceActivityHeader);
