import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/protected/Dashboard';
import UserWebLayout from '../layouts/protected/UserWebLayout';
import Placeholder from '../pages/protected/Placeholder';
import Empty from '../pages/protected/not-found/Empty';
import { RecruitmentDashboard } from '../pages/protected/recruitment/RecruitmentDashboard';
import { Finder } from '../pages/protected/finder/Finder';

const ProtectedRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/user" element={<UserWebLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="app/:app" element={<Placeholder />} />
          <Route path="NotFound" element={<Empty />} />
        </Route>
        <Route path="/recruitment-dashboard" element={<UserWebLayout />}>
          <Route path="" element={<RecruitmentDashboard />} />
        </Route>
        <Route path="/finder" element={<UserWebLayout />}>
          <Route path="" element={<Finder />} />
        </Route>
        <Route path="*">"404 - Not Found"</Route>
      </Routes>
    </>
  );
};

export default ProtectedRoutes;
