import React, { useEffect, useState } from 'react';
import { ComboBox, Content, Dialog, Flex, Footer, Header, Item, Link, TextArea, TextField, useAsyncList, View } from '@adobe/react-spectrum';
import { SearchAutocomplete } from '@react-spectrum/autocomplete';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import { useBeforeRender } from '../../../../../hooks/useBeforeRender';
import { usePrevious } from '@uidotdev/usehooks';
import { ToastQueue } from '@react-spectrum/toast';
import { useDebounce } from '@uidotdev/usehooks';
import { ListCountryRequest } from '../../../../../services/soap/translate/reqeusts/ListCountryRequest';
import { ListLanguagesRequest } from '../../../../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { CreateContactRequest } from '../../../../../services/soap/main/requests/CreateContactRequest';
import { UpdateActivityRequest } from '../../../../../services/soap/project/requests/UpdateActivityRequest';
import { UpdateProjectRequest } from '../../../../../services/soap/project/requests/UpdateProjectRequest';
import { UpdateFolderRequest } from '../../../../../services/soap/project/requests/UpdateFolderRequest';
import { GetContactListRequest } from '../../../../../services/soap/main/requests/GetContactListRequest';
import { GetContactRequest } from '../../../../../services/soap/main/requests/GetContactRequest';
import styles from './ExternalNewContacts.module.css';

interface ExternalNewContactProps {
  selectedItem: any;
  currentItem: any;
  FPADataTypes: any;
  updateItem: (request: any, message: string) => void;
  openDialog: (isOpen: boolean) => void;
  closeDialog: (reload: boolean) => void;
}

type formDataType = {
  name: string;
  nameDisable: boolean;
  surname: string;
  surnameDisable: boolean;
  phone: string;
  phoneDisable: boolean;
  countryId: string;
  countryIdDisable: boolean;
  email: string;
  emailDisable: boolean;
  languageId: string;
  languageIdDisable: boolean;
  description: string;
};

const ExternalNewContact: React.FC<ExternalNewContactProps> = ({ selectedItem, currentItem, FPADataTypes, updateItem, openDialog, closeDialog }) => {
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const { translateService, mainService, projectService, store } = useDependency();
  const [countryList, setCountryList] = useState<any>([]);
  const [langList, setLangList] = useState<any>([]);
  const [formValue, setFormValue] = useState<formDataType>({
    name: '',
    nameDisable: false,
    surname: '',
    surnameDisable: false,
    phone: '',
    phoneDisable: false,
    countryId: '',
    countryIdDisable: false,
    email: '',
    emailDisable: false,
    languageId: '131427101',
    languageIdDisable: false,
    description: '',
  });
  const debouncedSurNameFilterTerm = useDebounce(formValue.surname, 1000);
  const debouncedEmailFilterTerm = useDebounce(formValue.email, 1000);

  const [validationMessages, setValidationMessages] = useState<any>({ surname: '', email: '' });
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchResultsEmail, setSearchResultsEmail] = useState<any[]>([]);
  const previousSearchResults = usePrevious(searchResults);
  const [selectedContactId, setSelectedContactId] = useState<string>('');

  /** Code to hide Observer Error */
  useBeforeRender(() => {
    window.addEventListener('error', e => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      await loadDropDownData();
    })();
  }, []);

  const loadDropDownData = async () => {
    const [countryData, langData] = await Promise.all([
      translateService.listCountry(new ListCountryRequest(store.Server, store.SessionId, {})),
      translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId)),
    ]);

    setCountryList(countryData.ROWS?.ROW);
    setLangList(langData?.LIST);
  };

  const BackContactButton = () => (
    <Flex UNSAFE_className={styles.button_container_new_contact_second}>
      {!isMobile && <i className={`${styles.icon_add} bi bi-arrow-left fs-5`} />}
      <Link isQuiet UNSAFE_className={styles.back_text} onPress={() => openDialog(true)}>
        {isMobile ? <i className={`${styles.icon_add} bi bi-arrow-left fs-5`} /> : t('back', { ns: 'layout_components' })}
      </Link>
    </Flex>
  );

  const ContactActions = () => (
    <Flex UNSAFE_className={styles.button_container_new_contact}>
      <Flex UNSAFE_className={styles.new_contact_cancel}>
        <Link isQuiet UNSAFE_className={styles.new_contact_cancel_text} onPress={() => closeDialog(false)}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex>
      <Flex UNSAFE_className={styles.new_contact_add}>
        <Link isQuiet UNSAFE_className={styles.new_contact_add_text} onPress={validateAndSubmit}>
          {t('add_contact', { ns: 'layout_components' })}
        </Link>
      </Flex>
    </Flex>
  );

  const ContactButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'}>
      <BackContactButton />
      <ContactActions />
    </Flex>
  );

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    //console.log('validateEmail(formValue.email)', validateEmail(formValue.email));
    const fields = [
      { key: 'surname', condition: formValue.surname === '' },
      { key: 'email', condition: validateEmail(formValue.email) !== true },
    ];
    const isValid = fields.every(({ key, condition }) => {
      //console.log('validationMessages[key]', validationMessages[key] === '', condition);
      const isFieldValid = validationMessages[key] === '' && !condition;
      //console.log('isFieldValid', isFieldValid);
      if (!isFieldValid) {
        validationMessages[key] = 'required';
        setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
      }
      return isFieldValid;
    });

    return isValid;
  };

  const createContactData = () => {
    const CONTACTS = [];
    if (formValue.email) {
      CONTACTS.push({ content: formValue.email, description: '', isMain: 1, isPrimary: 1, type: 132026101, typeName: 'E-mail', typeType: 1 });
    }
    if (formValue.phone) {
      CONTACTS.push({ content: formValue.phone, description: '', isMain: 0, isPrimary: 1, type: 132030101, typeName: 'Telefon', typeType: 2 });
    }

    const contactData: any = {
      name3: formValue.name,
      name1: formValue.surname,
      PERSONINFO: { preferedLang: formValue.languageId },
      description: formValue.description,
    };

    if (formValue.countryId) {
      contactData.ADDRESSES = { ADDRESS: [{ country: formValue.countryId, isPrimary: 1, type: 132022101 }] };
    }

    if (CONTACTS.length > 0) {
      contactData.CONTACTS = { CONTACT: CONTACTS };
    }

    return contactData;
  };

  const updateExternalContacts = (contactId: string) => ({
    count: 1,
    ROWS: [
      {
        EXTERNAL_CONTACT: {
          contactId,
          note: formValue.description,
          name1: formValue.surname,
          name2: '',
          name3: formValue.name,
          type: 1,
          email: formValue.email,
          phone: formValue.phone,
          mobile: '',
          role: '',
          deleteContact: 0,
        },
      },
    ],
  });

  const validateAndSubmit = async () => {
    //console.log('selectedContactId', selectedContactId);
    if (!selectedContactId && !validateFields()) return;

    let contactResp: any;
    if (!selectedContactId) {
      const contactData = createContactData();
      contactResp = await mainService.createContact(new CreateContactRequest(store.Server, store.SessionId, contactData));

      if (contactResp.result !== 'OK') {
        ToastQueue.negative(contactResp.EXCEPTION.message, { timeout: 3000 });
        return;
      }
    }

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...currentItem.current,
        EXTERNAL_CONTACTS: updateExternalContacts(selectedContactId || contactResp.newId),
      },
    };

    const updateFunctions = {
      [FPADataTypes.ACTIVITY]: () => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)),
      [FPADataTypes.PROJECT]: () => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)),
      [FPADataTypes.FOLDER]: () => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)),
    };

    updateItem(updateFunctions[selectedItem.type], 'Contact added successfully');
    closeDialog(true);
  };

  let listSurName = useAsyncList({
    async load({ filterText }) {
      let newResultArray: { [key: string]: Object }[] = [];
      console.log('filterText', filterText);
      if (filterText === '')
        return {
          items: newResultArray,
          cursor: undefined,
        };
      if (debouncedSurNameFilterTerm) {
        let result: any = await mainService.getContactList(
          new GetContactListRequest(
            store.Server,
            store.SessionId,
            undefined,
            0,
            20,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            '*' + filterText
          )
        );

        //console.log('result', result);
        if (result?.count > 0) {
          //console.log(result.ITEMS.some((item: any) => 'FOLDER' in item?.ITEM));
          result.CONTACTS?.CONTACT?.forEach((contact: any) => {
            //console.log('contact', contact);
            newResultArray.push({
              ...contact,
              id: contact?.id,
              name: `${contact?.name3} ${contact?.name1}`,
            });
          });
        } else {
          newResultArray = previousSearchResults || [];
        }

        //console.log(newResultArray);
        setSearchResults(newResultArray);
        return {
          items: newResultArray,
          cursor: undefined,
        };
      } else {
        newResultArray = previousSearchResults || [];
        return {
          items: newResultArray,
          cursor: undefined,
        };
      }
    },
  });

  let listEmail = useAsyncList({
    async load({ filterText }) {
      let newResultArray: { [key: string]: Object }[] = [];
      if (filterText === '')
        return {
          items: newResultArray,
          cursor: undefined,
        };
      if (debouncedEmailFilterTerm) {
        let result: any = await mainService.getContactList(
          new GetContactListRequest(
            store.Server,
            store.SessionId,
            undefined,
            0,
            20,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            filterText
          )
        );

        //console.log('result', result);
        if (result?.count > 0) {
          //console.log(result.ITEMS.some((item: any) => 'FOLDER' in item?.ITEM));
          result.CONTACTS?.CONTACT?.forEach((contact: any) => {
            //console.log('contact', contact);
            newResultArray.push({
              ...contact,
              id: contact?.id,
              name: `${contact?.name3} ${contact?.name1}`,
            });
          });
        } else {
          newResultArray = previousSearchResults || [];
        }

        //console.log(newResultArray);
        setSearchResultsEmail(newResultArray);
        return {
          items: newResultArray,
          cursor: undefined,
        };
      } else {
        newResultArray = previousSearchResults || [];
        return {
          items: newResultArray,
          cursor: undefined,
        };
      }
    },
  });

  async function enableDisableFields(selectedValue: any, selectedControl: string) {
    if (selectedValue === '') {
      setSelectedContactId('');
      setFormValue((prevValue: any) => ({
        ...prevValue,
        surnameDisable: false,
        nameDisable: false,
        phoneDisable: false,
        emailDisable: false,
        countryIdDisable: false,
        languageIdDisable: false,
      }));
      return;
    }
    //console.log('searchResultsEmail', selectedValue, searchResultsEmail);
    let contactData: any = {};

    if (selectedControl === 'surname') {
      contactData = searchResults.find((item: any) => item.name === selectedValue);
      listEmail.setFilterText(contactData?.email);
      if (!contactData) return;
    } else if (selectedControl === 'email') {
      contactData = searchResultsEmail.find((item: any) => item.email == selectedValue);
      listSurName.setFilterText(contactData?.name3);
    }

    if (!contactData?.id) return;

    const contactId = contactData?.id;
    setSelectedContactId(contactId);
    //console.log('contactData', contactData);
    const contactDetail: any = await mainService.getContact(new GetContactRequest(store.Server, store.SessionId, contactId, undefined, contactId));

    const commonFields = {
      name: contactData?.name3,
      nameDisable: true,
      phone: contactData?.phone,
      phoneDisable: true,
      countryId: countryList.find((country: any) => country.name === contactData?.country)?.countryId,
      countryIdDisable: true,
      languageId: contactDetail?.PERSON?.PERSONINFO?.preferedLang,
      languageIdDisable: true,
    };

    const condiotionalFields = selectedControl === 'surname' ? { email: contactData?.email, emailDisable: true } : { surname: contactData?.name1, surnameDisable: true };

    setFormValue((prevValue: any) => ({
      ...prevValue,
      ...commonFields,
      ...condiotionalFields,
    }));
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      validateAndSubmit();
    }
  };

  return (
    <Dialog>
      <Header UNSAFE_className={styles.new_contact_heading}>{t('new_contact', { ns: 'layout_components' })}</Header>
      <Content height={'350px'}>
        <div style={{ width: '100%' }} onKeyUp={handleKeyUp}>
          <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
            <View UNSAFE_className={styles.add_contact_parent}>
              {/* <View UNSAFE_className={styles.add_client_contact_heading}>New Contact</View> */}
              <Flex UNSAFE_className={styles.form_parent}>
                <Flex UNSAFE_className={styles.form_group}>
                  {isMobile ? (
                    <table width={'100%'}>
                      <tbody>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('name', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>

                              <TextField
                                value={formValue.name}
                                isDisabled={formValue.nameDisable}
                                width={'100%'}
                                onChange={e => {
                                  setFormValue((prevValue: any) => ({ ...prevValue, name: e }));
                                }}
                              ></TextField>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('surname', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>
                              <SearchAutocomplete
                                id="searchSurName"
                                isDisabled={formValue.surnameDisable}
                                label=""
                                width={'100%'}
                                items={listSurName.items}
                                inputValue={listSurName.filterText}
                                onInputChange={(value: string) => {
                                  listSurName.setFilterText(value);
                                  setFormValue((prevValue: any) => ({ ...prevValue, surname: value }));
                                  if (value !== '') setValidationMessages((prev: any) => ({ ...prev, surname: '' }));
                                  enableDisableFields(value, 'surname');
                                }}
                                loadingState={listSurName.loadingState}
                                onLoadMore={listSurName.loadMore}
                                validationState={validationMessages.surname !== 'required' ? 'valid' : 'invalid'}
                              >
                                {(item: any) => <Item key={item.name}>{item.name}</Item>}
                              </SearchAutocomplete>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('phone', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>
                              <TextField
                                type="number"
                                value={formValue.phone}
                                isDisabled={formValue.phoneDisable}
                                width={'100%'}
                                onChange={e => {
                                  setFormValue((prevValue: any) => ({ ...prevValue, phone: e }));
                                }}
                              ></TextField>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <View UNSAFE_className={styles.form_label_text}>{t('country', { ns: 'layout_components' })}</View>
                              </Flex>
                              <ComboBox
                                defaultSelectedKey={formValue.countryId}
                                selectedKey={formValue.countryId}
                                isDisabled={formValue.countryIdDisable}
                                width={'100%'}
                                label=""
                                defaultItems={countryList}
                                onSelectionChange={value => {
                                  if (value !== null) {
                                    setFormValue((prevValue: any) => ({ ...prevValue, countryId: value }));
                                  }
                                }}
                              >
                                {(item: any) => <Item key={item.countryId}>{item.countryName}</Item>}
                              </ComboBox>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('email', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>
                              <SearchAutocomplete
                                id="searchEmail"
                                isDisabled={formValue.emailDisable}
                                label=""
                                width={'100%'}
                                items={listEmail.items}
                                inputValue={listEmail.filterText}
                                onInputChange={(value: string) => {
                                  listEmail.setFilterText(value);
                                  setFormValue((prevValue: any) => ({ ...prevValue, email: value }));
                                  enableDisableFields(value, 'email');
                                }}
                                loadingState={listEmail.loadingState}
                                onLoadMore={listEmail.loadMore}
                              >
                                {(item: any) => <Item key={item.email}>{item.email}</Item>}
                              </SearchAutocomplete>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <View UNSAFE_className={styles.form_label_text}>{t('language', { ns: 'layout_components' })}</View>
                              </Flex>
                              <ComboBox
                                defaultInputValue={formValue.languageId}
                                isDisabled={formValue.languageIdDisable}
                                width={'100%'}
                                label=""
                                defaultItems={langList}
                                selectedKey={formValue.languageId}
                                onSelectionChange={value => {
                                  if (value !== null) {
                                    setFormValue((prevValue: any) => ({ ...prevValue, languageId: value }));
                                  }
                                }}
                              >
                                {(item: any) => <Item key={item.LANGUAGE.id}>{item.LANGUAGE.languageLong}</Item>}
                              </ComboBox>
                            </Flex>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <table width={'100%'}>
                      <tbody>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('name', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>

                              <TextField
                                autoFocus
                                autoComplete="false"
                                value={formValue.name}
                                isDisabled={formValue.nameDisable}
                                width={'100%'}
                                onChange={e => {
                                  setFormValue((prevValue: any) => ({ ...prevValue, name: e }));
                                }}
                              ></TextField>
                            </Flex>
                          </td>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('surname', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>
                              <SearchAutocomplete
                                id="searchSurName"
                                isDisabled={formValue.surnameDisable}
                                label=""
                                width={'100%'}
                                items={listSurName.items}
                                inputValue={listSurName.filterText}
                                onInputChange={(value: string) => {
                                  listSurName.setFilterText(value);
                                  setFormValue((prevValue: any) => ({ ...prevValue, surname: value }));
                                  if (value !== '') setValidationMessages((prev: any) => ({ ...prev, surname: '' }));
                                  enableDisableFields(value, 'surname');
                                }}
                                loadingState={listSurName.loadingState}
                                onLoadMore={listSurName.loadMore}
                                validationState={validationMessages.surname !== 'required' ? 'valid' : 'invalid'}
                              >
                                {(item: any) => <Item key={item.name}>{item.name}</Item>}
                              </SearchAutocomplete>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('phone', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>
                              <TextField
                                type="number"
                                value={formValue.phone}
                                isDisabled={formValue.phoneDisable}
                                width={'100%'}
                                onChange={e => {
                                  setFormValue((prevValue: any) => ({ ...prevValue, phone: e }));
                                }}
                              ></TextField>
                            </Flex>
                          </td>
                          <td>
                            {' '}
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <View UNSAFE_className={styles.form_label_text}>{t('country', { ns: 'layout_components' })}</View>
                              </Flex>
                              <ComboBox
                                defaultSelectedKey={formValue.countryId}
                                selectedKey={formValue.countryId}
                                isDisabled={formValue.countryIdDisable}
                                width={'100%'}
                                label=""
                                defaultItems={countryList}
                                onSelectionChange={value => {
                                  if (value !== null) {
                                    setFormValue((prevValue: any) => ({ ...prevValue, countryId: value }));
                                  }
                                }}
                              >
                                {(item: any) => <Item key={item.countryId}>{item.countryName}</Item>}
                              </ComboBox>
                            </Flex>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <Flex UNSAFE_className={styles.form_label}>
                                  <View UNSAFE_className={styles.form_label_text}>{t('email', { ns: 'layout_components' })}</View>
                                </Flex>
                              </Flex>
                              <SearchAutocomplete
                                id="searchEmail"
                                isDisabled={formValue.emailDisable}
                                label=""
                                width={'100%'}
                                items={listEmail.items}
                                inputValue={listEmail.filterText}
                                onInputChange={(value: string) => {
                                  listEmail.setFilterText(value);
                                  setFormValue((prevValue: any) => ({ ...prevValue, email: value }));
                                  enableDisableFields(value, 'email');
                                }}
                                loadingState={listEmail.loadingState}
                                onLoadMore={listEmail.loadMore}
                              >
                                {(item: any) => <Item key={item.email}>{item.email}</Item>}
                              </SearchAutocomplete>
                            </Flex>
                          </td>
                          <td>
                            {' '}
                            <Flex UNSAFE_className={styles.form_input}>
                              <Flex UNSAFE_className={styles.form_label_container}>
                                <View UNSAFE_className={styles.form_label_text}>{t('language', { ns: 'layout_components' })}</View>
                              </Flex>
                              <ComboBox
                                defaultInputValue={formValue.languageId}
                                isDisabled={formValue.languageIdDisable}
                                width={'100%'}
                                label=""
                                defaultItems={langList}
                                selectedKey={formValue.languageId}
                                onSelectionChange={value => {
                                  if (value !== null) {
                                    setFormValue((prevValue: any) => ({ ...prevValue, languageId: value }));
                                  }
                                }}
                              >
                                {(item: any) => <Item key={item.LANGUAGE.id}>{item.LANGUAGE.languageLong}</Item>}
                              </ComboBox>
                            </Flex>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </Flex>
                <Flex UNSAFE_className={styles.form_input_large}>
                  <Flex UNSAFE_className={styles.form_label_container}>
                    <Flex UNSAFE_className={styles.form_label}>
                      <View UNSAFE_className={styles.form_label_text}>{t('description', { ns: 'layout_components' })}</View>
                    </Flex>
                  </Flex>

                  <TextArea
                    width={isMobile ? '100%' : '95%'}
                    onChange={e => {
                      setFormValue((prevValue: any) => ({ ...prevValue, description: e }));
                    }}
                  ></TextArea>
                </Flex>
              </Flex>
            </View>
          </Flex>
        </div>
      </Content>
      <Footer>
        <View UNSAFE_className={styles.footer}>
          <ContactButtons />
        </View>
      </Footer>
    </Dialog>
  );
};

export default ExternalNewContact;
