import { useState, useEffect, FunctionComponent, useCallback, useRef } from 'react';
import { Flex, View, ActionButton, Menu, MenuTrigger, Item, Text, Image, Avatar } from '@adobe/react-spectrum';

import AppsIcon from '@spectrum-icons/workflow/Apps';

import { useLocation, useNavigate } from 'react-router-dom';
import { SessionInfo } from '../../../data/SessionInfo';
import { ToastQueue } from '@react-spectrum/toast';
import AppMenu from '../../../components/protected/AppMenu/AppMenu';
import styles from './UserHeader.module.css';
import ConstantUtils from '../../../utils/ConstantUtils';
import { LogoutRequest } from '../../../services/soap/main/requests/LogoutRequest';
import { useDependency } from '../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../hooks/UseViewInfo';
import { useDeviceInfo } from '../../../hooks/UseDeviceInfo';
import GlobalSearch from '../../../components/protected/Search/GlobalSearch';
import { UpdateFavouriteContextRequest } from '../../../services/soap/project/requests/UpdateFavouriteContextRequest';
import { OneTimeStorage } from '../../../services/LocalStorage/OneTimeStorage';
import { OfflineIcon, SearchIcon } from '../../../components/protected/Icons/IconsLib';
import { AppTabContainer } from '../../../components/protected/AppTabs/AppTabContainer';
import { AppMenuItemType } from '../../../components/protected/AppMenu/AppMenu.types';
import { AppTabContainerItemType } from '../../../components/protected/AppTabs/AppTabContainerItem';
import { invokeFinderApp } from '../../../utils/NavigationUtils';
// import LocaleSwitcher from '../../../i18n/LocaleSwitcher';

type UserHeaderType = {
  groups: any[];
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  isSearchOpen: boolean;
  setIsSearchOpen: (isSearchOpen: boolean) => void;
  onAppClicked: (item: AppMenuItemType) => void;
  closeApp: (item: string) => void;
  projectStatusMap: any;
  folderStatusMap: any;
  activityStatusMap: any;
  openedApps: AppTabContainerItemType[];
  selectedApp: string;
  onSelectedAppChanged: (itemKey: string) => void;
  onHelpClicked: () => void;
  onLogout: () => void;
};

const UserHeader: FunctionComponent<UserHeaderType> = ({
  groups,
  isMenuOpen,
  setIsMenuOpen,
  isSearchOpen,
  setIsSearchOpen,
  onAppClicked,
  closeApp,
  projectStatusMap,
  folderStatusMap,
  activityStatusMap,
  openedApps,
  selectedApp,
  onSelectedAppChanged,
  onHelpClicked,
  onLogout
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const session: SessionInfo = state.session;
  const { isInternetConnected } = useDeviceInfo();
  const [isOnline, setIsOnline] = useState(isInternetConnected);
  const [isMobileView, setIsMobileView] = useState(false);
  const tabsContainerRef = useRef<any>(null);
  const [tabsAreaWidth, setTabsAreaWidth] = useState(0);

  const { mainService, projectService, store } = useDependency();
  const { isMobile } = useViewInfo();

  useEffect(() => {
    setIsMobileView(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!tabsContainerRef.current) return;
    if (!tabsContainerRef.current.UNSAFE_getDOMNode()) return;
    var dom = tabsContainerRef.current.UNSAFE_getDOMNode();
    if (!dom) return;

    setTabsAreaWidth(dom.clientWidth);
  }, [tabsContainerRef.current]);
  useEffect(() => {
    setIsOnline(isInternetConnected);
  }, [isInternetConnected]);

  const updateFavouriteContextCallback = useCallback(
    async (contextId: number, contextType: string) => {
      try {
        var folderId = contextType === 'FOLDER' ? contextId : undefined;
        var projectId = contextType === 'PROJECT' ? contextId : undefined;
        var activityId = contextType === 'ACTIVITY' ? contextId : undefined;

        await projectService.updateFavouriteContext(new UpdateFavouriteContextRequest(store.Server, store.SessionId, folderId, projectId, activityId));
      } catch (error) {
        console.log(error);
      }
    },
    [projectService, store.Server, store.SessionId]
  );

  const handleMenuAction = (key: string) => {
    switch (key) {
      case 'logout':
        onLogout();
        // ToastQueue.info('Are you sure to want to logout?', {
        //   actionLabel: 'Yes',
        //   onAction: async () => {
        //     try {
        //       await mainService.logout(new LogoutRequest(store.SessionId, store.Server));
        //       await OneTimeStorage.getInstance().clear();
        //       store.clear();
        //     } catch (error) {
        //       console.log('error in handleMenuAction', error);
        //     }
        //     navigate('/');
        //   },
        //   shouldCloseOnAction: true,
        // });
  
        break;
      case 'help':
        onHelpClicked();
        break;
      default:
        break;
    }
  };

  const handleAppMenuClicked = useCallback(
    (e: any) => {
      e.stopPropagation();
      // ConstantUtils.isClicked = true;
      // ConstantUtils.appMenuOpen = !isMenuOpen;
      setIsMenuOpen(!isMenuOpen);
    },
    [isMenuOpen, setIsMenuOpen]
  );

  const handleSearchSelected = useCallback(
    async (item: any) => {
      await updateFavouriteContextCallback(item.contextId, item.contextType);
      setIsSearchOpen(false);
      invokeFinderApp(item.contextId);
    },
    [setIsSearchOpen, updateFavouriteContextCallback]
  );

  const swapOpenItemLocations = (itemKey1: string, itemKey2: string) => {
    var index1 = openedApps.findIndex(i => i.itemKey === itemKey1);
    var index2 = openedApps.findIndex(i => i.itemKey === itemKey2);
    var temp = openedApps[index1];
    openedApps[index1] = openedApps[index2];
    openedApps[index2] = temp;
    onSelectedAppChanged(itemKey2);
  };

  return (
    <>
      {isSearchOpen && (
        <GlobalSearch
          close={() => setIsSearchOpen(false)}
          onSearchSelected={handleSearchSelected}
          projectStatusMap={projectStatusMap}
          folderStatusMap={folderStatusMap}
          activityStatusMap={activityStatusMap}
        />
      )}
      <Flex direction={'row'} justifyContent="space-between" alignItems="center">
        <View>
          <Flex direction="row" alignItems="center">
            <View>
              <Image src="../assets/images/atollon-logo.svg" alt={'Atollon'} height={60} />
            </View>
            <View>
              <div className={styles.AppMenuButton} onClick={handleAppMenuClicked}>
                <AppsIcon size="M" />
              </div>
            </View>
          </Flex>
          {isMenuOpen && !isMobileView && <AppMenu onAppClicked={onAppClicked} onClosed={() => setIsMenuOpen(false)} groups={groups} />}
        </View>
        <View flexGrow={3} alignSelf="flex-end" UNSAFE_className={styles.tabs_area} ref={tabsContainerRef}>
          <AppTabContainer
            items={openedApps}
            closeApp={closeApp}
            selected_app={selectedApp}
            onSelectedAppChanged={onSelectedAppChanged}
            areaWidth={tabsAreaWidth}
            swapOpenItemLocations={swapOpenItemLocations}
          />
        </View>
        <View paddingEnd={10}>
          <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'size-200'}>
            <View>
              <div>{!isOnline && <OfflineIcon size={16} />}</div>
            </View>
            <span onClick={() => setIsSearchOpen(true)} className={styles.searchIcon}>
              <SearchIcon size={24} />
            </span>
            <View>
              <MenuTrigger>
                <ActionButton UNSAFE_style={{ border: 'none' }}>
                  <View paddingEnd={10}>
                    <Avatar src="../assets/images/avatar--desktop--light@2x.png" size={30} />
                  </View>
                  <View>
                    <div style={{ textAlign: 'left' }}>
                      <Text>
                        <b>
                          {session.sessionInfo.firstName} {session.sessionInfo.lastName}
                        </b>
                      </Text>
                      <br />
                      <Text UNSAFE_style={{ color: 'gray', fontSize: '11px' }}>{session.sessionInfo.organizationName}</Text>
                    </div>
                  </View>
                </ActionButton>
                <Menu onAction={key => handleMenuAction(key + '')}>
                  <Item key="help">Help</Item>
                  <Item key="logout">Logout</Item>
                </Menu>
              </MenuTrigger>
            </View>
            {/* <View>
              <LocaleSwitcher />
            </View> */}
          </Flex>
        </View>
      </Flex>
    </>
  );
};

export default UserHeader;
